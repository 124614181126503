import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ListData, StandardApi, StandardFilter } from '../../standard-api';
import { environment } from '../../../../../environments/environment';
import { Asset } from './asset';

export class AssetsFilter extends StandardFilter {
  search?: string;
}

@Injectable({providedIn: 'root'})
export class AssetsService extends StandardApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(asset: Asset): Observable<Asset> {
    return this.http.post<Asset>(`${environment.coreSrv}/assets`, asset);
  }

  find(filter: AssetsFilter): Observable<ListData<Asset>> {
    return this.list(`${environment.coreSrv}/assets`, {params: this.params(filter)});
  }

  findById(id: string): Observable<Asset> {
    return this.http.get<Asset>(`${environment.coreSrv}/assets/${id}`);
  }

  update(id: string, asset: Asset): Observable<Asset> {
    return this.http.put<Asset>(`${environment.coreSrv}/assets/${id}`, asset);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.coreSrv}/assets/${id}`);
  }
}
