import { Component, Input } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-json-preview',
  templateUrl: './json-preview.component.html',
  styleUrls: ['./json-preview.component.scss']
})
export class JsonPreviewComponent {

  visible: boolean = !environment.production;

  @Input() json: any;
}
