import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AvatarOptionsHelper } from './avatar-options-helper';
import { AvatarHelper } from './avatar-helper';

@Component({
  selector: 'app-avatar-generator',
  templateUrl: './avatar-generator.component.html',
  styleUrls: ['./avatar-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarGeneratorComponent {

  private svg: BehaviorSubject<string> = new BehaviorSubject<string>(AvatarHelper.getSvg(new AvatarOptionsHelper().random()));

  svg$: Observable<string> = this.svg.asObservable();
  html$: Observable<SafeHtml> = this.svg$.pipe(map(svg => {
    if (!svg) {
      return svg;
    }
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }));
  src$: Observable<SafeResourceUrl> = this.svg$.pipe(map(svg => {
    if (!svg) {
      return svg;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.getImage());
  }));

  @Input() set options(options: AvatarOptionsHelper) {
    this.svg.next(AvatarHelper.getSvg(options));
    this.updated.next(this.svg.getValue());
  }

  @Output() updated: EventEmitter<string> = new EventEmitter<string>();

  constructor(private sanitizer: DomSanitizer) {
  }

  getSvg(): string {
    return this.svg.getValue();
  }

  getImage(): string {
    return `data:image/svg+xml;base64,${btoa(this.getSvg())}`;
  }

  random(): void {
    this.svg.next(AvatarHelper.getSvg(new AvatarOptionsHelper().random()));
    this.updated.next(this.svg.getValue());
  }
}
