import { Component, Input } from '@angular/core';
import moment from 'moment';

import { Product } from '../../../core/api/core/products/product';

@Component({
  selector: 'app-product-status',
  templateUrl: './product-status.component.html',
  styleUrls: ['./product-status.component.scss']
})
export class ProductStatusComponent {

  @Input() product?: Product;

  now: Date | string = moment().format('YYYY-MM-DD');

  constructor() {
  }
}
