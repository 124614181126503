import { Component, Inject, Input, OnInit, Optional, Self } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Quill from 'quill';

import { ElementBase } from '../../../core/forms/element-base';

@Component({
  selector: 'app-standard-rte',
  templateUrl: './standard-rte.component.html',
  styleUrls: ['./standard-rte.component.scss']
})
export class StandardRteComponent extends ElementBase<string> implements OnInit {

  static nextId = 0;

  private instance: Quill | null = null;

  model: NgModel | null = null;

  @Input() id = `standard-rte-${StandardRteComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() disabled = false;
  @Input() placeholder: string | null = null;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnInit(): void {
    this.instance = new Quill('#quill', {
      modules: {
        toolbar: [
          ['bold', 'italic'],
          ['link', 'blockquote', 'code', 'image'],
          [
            {list: 'ordered'},
            {list: 'bullet'}
          ]
        ]
      },
      placeholder: this.placeholder as string,
      theme: 'snow'
    });
    if (this.disabled) {
      this.instance.disable();
    }
    this.instance.on('text-change', value => {
      this.value = this.instance?.getText() as string;
    });
  }
}
