import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ListData, StandardApi, StandardFilter } from '../../standard-api';
import { Tag, TagType } from './tag';
import { environment } from '../../../../../environments/environment';

export class TagsFilter extends StandardFilter {
  type?: TagType;
  value?: string;
}

@Injectable({providedIn: 'root'})
export class TagsService extends StandardApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(`${environment.coreSrv}/tags`, tag);
  }

  find(filter: TagsFilter): Observable<ListData<Tag>> {
    return this.list(`${environment.coreSrv}/tags`, {params: this.params(filter)});
  }

  findById(id: string): Observable<Tag> {
    return this.http.get<Tag>(`${environment.coreSrv}/tags/${id}`);
  }

  update(id: string, tag: Tag): Observable<Tag> {
    return this.http.put<Tag>(`${environment.coreSrv}/tags/${id}`, tag);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.coreSrv}/tags/${id}`);
  }
}
