<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<div class="dropzone" [class.dropzone-multiple]="multiple" [class.dropzone-single]="!multiple" #dropzone>
  <div class="fallback">
    <div class="custom-file">
      <input class="custom-file-input" id="fileUpload" [multiple]="multiple" type="file"/>
      <label class="custom-file-label" for="fileUpload">
        Choose file
      </label>
    </div>
  </div>

  <!--  <ul class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">-->
  <!--    <li class="list-group-item px-0" *ngFor="let file of files$|async">-->
  <!--      <div class="row align-items-center">-->
  <!--        <div class="col-auto">-->
  <!--          <div class="avatar">-->
  <!--            <img alt="..." class="avatar-img rounded" data-dz-thumbnail="" src="..."/>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col ml&#45;&#45;3">-->
  <!--          <h4 class="mb-1" data-dz-name="">...</h4>-->
  <!--          <p class="small text-muted mb-0" data-dz-size="">...</p>-->
  <!--        </div>-->
  <!--        <div class="col-auto">-->
  <!--          <button data-dz-remove="true" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </li>-->
  <!--  </ul>-->
</div>
