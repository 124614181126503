import { Component, Inject, Input, Optional, Self, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ElementBase } from '../../../core/forms/element-base';

@Component({
  selector: 'app-standard-checkbox',
  templateUrl: './standard-checkbox.component.html',
  styleUrls: ['./standard-checkbox.component.scss']
})
export class StandardCheckboxComponent extends ElementBase<boolean> {

  static nextId = 0;

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;

  @Input() id = `standard-checkbox-${StandardCheckboxComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() disabled = false;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }
}
