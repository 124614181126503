import { AbstractControl, Validator, ValidatorFn, Validators } from '@angular/forms';
import { of } from 'rxjs';

export type AsyncValidatorArray = any[];

export type ValidatorArray = any[];

const normalizeValidator =
  (validator: Validator | ValidatorFn): any => {
    const func = (validator as Validator).validate.bind(validator);
    if (typeof func === 'function') {
      return (c: AbstractControl) => func(c);
    } else {
      return validator;
    }
  };

export const composeValidators = (validators: ValidatorArray): any => {
  if (validators == null || validators.length === 0) {
    return null;
  }
  return Validators.compose((validators as any).map(normalizeValidator));
};

export const validate = (validators: ValidatorArray, asyncValidators: AsyncValidatorArray) => {
  return (control: AbstractControl | null) => {
    const synchronousValid = () => composeValidators(validators)(control);

    if (asyncValidators) {
      const asyncValidator = composeValidators(asyncValidators);

      return asyncValidator(control).map((v: any) => {
        const secondary = synchronousValid();
        if (secondary || v) { // compose async and sync validator results
          return Object.assign({}, secondary, v);
        }
      });
    }

    if (validators) {
      return of(synchronousValid());
    }

    return of(null);
  };
};

export const message = (validator: { [validator: string]: any }, key: string): { key: string, args?: any } => {
  switch (key) {
    case 'required':
      return {key: 'FORMS.VALIDATION.REQUIRED'};
    case 'pattern':
      return {key: 'FORMS.VALIDATION.PATTERN'};
    case 'minlength':
      return {key: 'FORMS.VALIDATION.MIN_LENGTH', args: {minlength: validator.minlength.requiredLength}};
    case 'maxlength':
      return {key: 'FORMS.VALIDATION.MAX_LENGTH', args: {maxlength: validator.maxlength.requiredLength}};
  }
  switch (typeof validator[key]) {
    case 'string':
      return {key: validator[key]};
    default:
      return {key: `Validation failed: ${key}`};
  }
};
