import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ElementBase } from '../../../core/forms/element-base';

@Component({
  selector: 'app-standard-select',
  templateUrl: './standard-select.component.html',
  styleUrls: ['./standard-select.component.scss']
})
export class StandardSelectComponent extends ElementBase<any> implements OnDestroy {

  static nextId = 0;

  private readonly searchFormSub?: Subscription;
  private scrollEndDisabled = false;

  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loading.asObservable();

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;
  @ViewChild('searchInput', {read: ElementRef}) searchInput: ElementRef | null = null;

  searchForm: FormGroup;

  @Output() searched: EventEmitter<string> = new EventEmitter();
  @Output() scrollEnd: EventEmitter<string> = new EventEmitter();

  @Input() id = `standard-select-${StandardSelectComponent.nextId++}`;
  @Input() placeholder: string | null = null;
  @Input() label: string | null = null;
  @Input() icon: string | null = null;
  @Input() disabled = false;
  @Input() readonly: boolean | null = null;
  @Input() required: boolean | null = null;
  @Input() multiple: boolean | null = null;
  @Input() search: boolean | null = null;
  @Input() showGroups: boolean | null = null;
  @Input() autoClose = true;
  @Input() items: any[] = [];
  @Input() scrollBuffer = 80;
  @Input() itemTemplate: TemplateRef<any> | null = null;
  @Input() selectionTemplate: TemplateRef<any> | null = null;
  @Input() trackedBy: (i1: any, i2: any) => boolean = (i1, i2) => i1 === i2;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService, fb: FormBuilder) {
    super(validators, asyncValidators, ngControl, translate);
    this.searchForm = fb.group({
      search: fb.control(null)
    });

    this.searchFormSub = this.searchForm.get('search')?.valueChanges.pipe(debounceTime(400)).subscribe(search => {
      this.searched.emit(search || null);
    });
  }

  ngOnDestroy(): void {
    this.searchFormSub?.unsubscribe();
    this.loading.complete();
    this.searched.complete();
    this.scrollEnd.complete();
  }

  setLoadingState(isLoading: boolean): void {
    this.loading.next(isLoading);
  }

  setScrollEndDisabled(isScrollEndDisabled: boolean): void {
    this.scrollEndDisabled = isScrollEndDisabled;
  }

  onOpenChange(open: boolean): void {
    if (open && this.search) {
      setTimeout(() => this.searchInput?.nativeElement.focus());
    }
  }

  clear(): void {
    if (!this.multiple) {
      return;
    }
    this.value = [];
  }

  selectAll(): void {
    if (this.showGroups) {
      let items: any = [];
      this.items.forEach(item => (items = items.concat(item.items)));
      this.value = items;
      return;
    }
    this.value = this.items.slice();
  }

  toggleMultiple(items: any[]): void {
    if (!this.multiple) {
      console.warn('Cannot select multiple - multiple not supported');
      return;
    }
    let anySelected = false;
    items.forEach(item => (anySelected = anySelected || this.isSelected(item)));
    if (anySelected) {
      this.value = (this.value || []).filter((value: any) => items.findIndex(item => this.trackedBy(item, value)) === -1);
    } else {
      this.value = (this.value || []).concat(items);
    }
  }

  onScroll(event: any): void {
    if (this.scrollEndDisabled || this.loading.getValue()) {
      return;
    }
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - this.scrollBuffer) {
      this.scrollEnd.emit();
    }
  }

  toggle(item: any): void {
    const isSelected = this.isSelected(item);
    if (this.multiple) {
      if (isSelected) {
        this.value = (this.value || []).filter((value: any) => !this.trackedBy(item, value));
      } else {
        this.value = (this.value || []).concat([item]);
      }
      return;
    }
    this.value = isSelected ? null : item;
  }

  isSelected(item: any): boolean {
    if (this.multiple) {
      return (this.value || []).findIndex((value: any) => this.trackedBy(item, value)) !== -1;
    }
    return this.trackedBy(item, this.value);
  }
}
