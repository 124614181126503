import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Store, StoreStatus } from './store';
import { Count, ListData, StandardApi, StandardFilter } from '../../standard-api';
import { environment } from '../../../../../environments/environment';

export class StoresFilter extends StandardFilter {
  search?: string;
}

@Injectable({providedIn: 'root'})
export class StoresService extends StandardApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(store: Store): Observable<Store> {
    return this.http.post<Store>(`${environment.storesSrv}/stores`, store);
  }

  find(filter: StoresFilter): Observable<ListData<Store>> {
    return this.list(`${environment.storesSrv}/stores`, {
      params: this.params(filter, [])
    });
  }

  count(filter: StoresFilter): Observable<Count> {
    return this.http.get<Count>(`${environment.storesSrv}/stores/_count`, {
      params: this.params(filter, [])
    });
  }

  findById(id: string): Observable<Store> {
    return this.http.get<Store>(`${environment.storesSrv}/stores/${id}`);
  }

  update(id: string, store: Store): Observable<Store> {
    return this.http.put<Store>(`${environment.storesSrv}/stores/${id}`, store);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.storesSrv}/stores/${id}`);
  }

  changeStatus(id: string, status: StoreStatus): Observable<Store> {
    return this.http.put<Store>(`${environment.storesSrv}/stores/${id}/_status`, {status});
  }
}
