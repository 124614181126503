<div class="modal-header">
  <h4 class="modal-title" translate>ASSETS.ASSETS</h4>
  <button type="button" class="close mr-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <app-standard-dropzone [url]="URL" [multiple]="multiple"
                             (uploading)="setUploading($event)"></app-standard-dropzone>
    </div>
  </div>
  <div class="row" *ngIf="uploading$|async">
    <div class="col-12 text-center">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>
  </div>
  <div class="row overflow-auto mt-2" style="max-height:30em;">
    <div class="col-3 mt-2 c-pointer" *ngFor="let asset of items$|async" (click)="select(asset)">
      <div class="d-flex flex-column border rounded px-4 py-2 position-relative"
           [class.border-primary]="asset.id&&selection[asset.id]">
        <i class="fa fa-fw fa-2x text-primary position-absolute mt-3 mr-3" style="top:0;right:0;"
           [class.fa-circle]="asset.id&&!selection[asset.id]"
           [class.fa-check-circle]="asset.id&&selection[asset.id]"></i>
        <img [src]="asset.url" [alt]="asset.title" style="object-fit:cover;height:12em;">
        <span class="text-truncate" [ngbTooltip]="asset.title">{{asset.title}}</span>
        <span class="text-muted">
          {{asset.size}}<span class="float-right">{{asset.modified|date:'dd.MM.yyyy H:m' }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="close()">
    <span translate>ACTIONS.CLOSE</span>
  </button>
  <button class="btn btn-primary" (click)="confirm()" [disabled]="assets.length===0">
    <span translate>ACTIONS.CONFIRM</span>
    <span *ngIf="assets.length>0"> ({{assets.length}})</span>
    <i class="fa fa-check-double fa-fw ml-2"></i>
  </button>
</div>
