import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';

import { StandardDropzoneComponent } from '../../../shared/components/standard-dropzone/standard-dropzone.component';
import { AlertsService } from '../../util/alerts.service';
import { AssetsFilter, AssetsService } from '../../api/core/assets/assets.service';
import { Asset } from '../../api/core/assets/asset';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-assets-modal',
  templateUrl: './assets-modal.component.html',
  styleUrls: ['./assets-modal.component.scss']
})
export class AssetsModalComponent implements OnInit, OnDestroy {

  readonly URL = `${environment.coreSrv}/assets`;

  private items: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
  private count: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private uploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  items$: Observable<Asset[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  uploading$: Observable<boolean> = this.uploading.asObservable();

  @Input() multiple = false;

  filter: AssetsFilter = new AssetsFilter(0, 12, '-modified');

  assets: Asset[] = [];
  selection: { [id: string]: boolean | undefined } = {};

  @ViewChild(StandardDropzoneComponent) dropzone?: StandardDropzoneComponent;

  constructor(private modal: NgbActiveModal, private assetsService: AssetsService, private alerts: AlertsService) {
  }

  ngOnInit(): void {
    this.fetch();
  }

  ngOnDestroy(): void {
    this.uploading.complete();
  }

  close(): void {
    this.modal.dismiss('Close click');
  }

  dismiss(): void {
    this.modal.dismiss('Close click');
  }

  select(asset: Asset): void {
    if (!asset || !asset.id) {
      return;
    }

    if (!this.multiple) {
      const state = this.selection[asset.id];
      Object.keys(this.selection).forEach(key => this.selection[key] = false);
      if (state !== true) {
        this.selection[asset.id] = true;
        this.assets = [asset];
        return;
      }
      this.selection[asset.id] = false;
      this.assets = [];
      return;
    }

    if (this.selection[asset.id] !== true) {
      this.selection[asset.id] = true;
      this.assets.push(asset);
      return;
    }
    this.selection[asset.id] = false;
    this.assets = this.assets.filter(a => a.id !== asset.id);
  }

  confirm(): void {
    this.modal.close({assets: this.assets});
  }

  setUploading(uploading: boolean): void {
    if (this.uploading.getValue() && !uploading) {
      this.fetch();
    }
    this.uploading.next(uploading);
  }

  private fetch(): void {
    this.pending.next(true);
    this.assetsService.find(this.filter).subscribe(data => {
      this.pending.next(false);
      this.items.next(data.values);
      this.count.next(data.count);
    }, (res: HttpErrorResponse) => {
      this.pending.next(false);
      this.alerts.httpError('Cannot fetch assets', res);
    });
  }
}

