import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';

import { AlertsService } from '../../util/alerts.service';
import { Store } from '../../api/stores/stores/store';
import { StoresService } from '../../api/stores/stores/stores.service';

@Component({
  selector: 'app-store-modal',
  templateUrl: './store-modal.component.html',
  styleUrls: ['./store-modal.component.scss']
})
export class StoreModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  saving$: Observable<boolean> = this.saving.asObservable();

  @Input() store: Store | null = null;

  form: FormGroup = this.fb.group({
    title: [null, Validators.required]
  });

  constructor(private modal: NgbActiveModal, private fb: FormBuilder, private alerts: AlertsService,
              private storesService: StoresService) {
  }

  ngOnInit(): void {
    if (this.store) {
      this.form.patchValue(this.store);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  close(): void {
    this.modal.dismiss('Close click');
  }

  dismiss(): void {
    this.modal.dismiss();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const store = {...this.store, ...this.form.getRawValue()};
    this.saving.next(true);
    if (store.id) {
      this.storesService.update(store.id, store).subscribe(updatedStore => {
        this.saving.next(false);
        this.modal.close({store: updatedStore});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot update store', res);
      });
    } else {
      this.storesService.create(store).subscribe(newStore => {
        this.saving.next(false);
        this.modal.close({store: newStore});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot create store', res);
      });
    }
  }
}
