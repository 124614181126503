import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty'
})
export class EmptyPipe implements PipeTransform {

  transform(value: any, emptiness: string = '/'): any {
    if (value === 0) {
      return 0;
    }
    if (value === false) {
      return false;
    }
    return value || emptiness;
  }
}
