<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<div class="input-group">
  <input class="form-control"
         [class.form-control-sm]="size==='sm'"
         [class.form-control-lg]="size==='lg'"
         ngbDatepicker #d="ngbDatepicker"
         autocomplete="off"
         [id]="id"
         [placeholder]="placeholder || ''"
         [minDate]="innerMinDateValue"
         [maxDate]="innerMaxDateValue"
         container="body"
         [firstDayOfWeek]="1"
         [disabled]="disabled"
         [(ngModel)]="date"
         [class.is-invalid]="invalid$|async">
  <div class="input-group-append c-pointer" (click)="!disabled&&d.toggle()">
    <span class="input-group-text">
      <i class="fa fa-fw fa-calendar-alt"></i>
    </span>
  </div>
</div>
<div class="invalid-feedback">
  <span *ngFor="let err of failures$|async;let last=last;">{{err}}<span *ngIf="!last">, </span></span>
</div>
