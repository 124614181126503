import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ListData, StandardApi, StandardFilter } from '../../standard-api';
import { Product } from './product';
import { environment } from '../../../../../environments/environment';

export class ProductsFilter extends StandardFilter {
  search?: string;
  categories?: string[];
}

@Injectable({providedIn: 'root'})
export class ProductsService extends StandardApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(product: Product): Observable<Product> {
    return this.http.post<Product>(`${environment.coreSrv}/products`, product);
  }

  find(filter: ProductsFilter): Observable<ListData<Product>> {
    return this.list(`${environment.coreSrv}/products`, {params: this.params(filter)});
  }

  findById(id: string): Observable<Product> {
    return this.http.get<Product>(`${environment.coreSrv}/products/${id}`);
  }

  update(id: string, product: Product): Observable<Product> {
    return this.http.put<Product>(`${environment.coreSrv}/products/${id}`, product);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.coreSrv}/products/${id}`);
  }
}
