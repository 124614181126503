<div ngbDropdown [autoClose]="autoClose" container="body" (openChange)="onOpenChange($event)">
  <label [hidden]="!label" [for]="id" [class.required-mark]="required||(required$()|async)"
         class="mb-0 form-control-label">{{label}}</label>
  <input type="hidden" [(ngModel)]="value">
  <button class="form-control d-flex align-items-center justify-content-between"
          [id]="id"
          ngbDropdownToggle
          [disabled]="disabled"
          [class.bg-transparent]="icon"
          [class.border-0]="icon"
          [class.p-0]="icon"
          [style.height]="icon?'auto':null"
          [class.is-invalid]="invalid$|async">
    <i *ngIf="icon" class="fas {{icon}} fa-fw fa-lg text-muted mr-1"></i>
    <div class="text-truncate" style="max-width:200px;">
      <ng-container
        *ngTemplateOutlet="selectionTemplate?selectionTemplate:defaultSelectionTemplate;context:{$implicit:multiple?(value||[]):value,multiple:multiple,placeholder:placeholder}"></ng-container>
    </div>
    <b class="caret ml-2 text-muted"></b>
  </button>
  <div class="invalid-feedback">
    <span *ngFor="let err of failures$|async;let last=last;">{{err}}<span *ngIf="!last">, </span></span>
  </div>
  <div ngbDropdownMenu class="standard-select-dropdown" [class.searchable]="search">
    <ng-container *ngIf="search">
      <form [formGroup]="searchForm">
        <div class="form-group mb-0">
          <div class="input-group">
            <input #searchInput formControlName="search" class="form-control border-0" placeholder="Search..."/>
            <div class="input-group-append">
              <span class="input-group-text bg-transparent border-0">
                <span *ngIf="loading$|async;else icon" class="spinner-border spinner-border-sm text-muted"></span>
                <ng-template #icon>
                  <i class="fa fa-search fa-fw text-muted"></i>
                </ng-template>
              </span>
            </div>
          </div>
        </div>
      </form>
      <div class="dropdown-divider mt-0 mb-0"></div>
    </ng-container>

    <ng-container *ngIf="multiple">
      <div class="btn-group btn-group-sm w-100" role="group">
        <button class="btn" (click)="clear()">
          <i class="fa fa-trash-alt text-danger mr-2"></i> <span class="text-muted" translate>ACTIONS.CLEAR</span>
        </button>
        <button class="btn" (click)="selectAll()">
          <i class="fa fa-check-double text-success mr-2"></i> <span class="text-muted"
                                                                     translate>ACTIONS.SELECT_ALL</span>
        </button>
      </div>
      <div class="dropdown-divider mt-0 mb-0"></div>
    </ng-container>

    <div class="overflow-auto mt-2" style="overflow-x:hidden!important;" [style.max-height.px]="300"
         (scroll)="onScroll($event)">
      <ng-container *ngFor="let item of items;let index=index; trackBy: trackedBy">
        <h6 *ngIf="showGroups && item.group as group" class="dropdown-header pl-2 c-pointer"
            (click)="toggleMultiple(item.items)">{{group}}</h6>
        <ng-container *ngIf="item.items;else ungroupedItem">
          <div *ngFor="let subItem of item.items;" ngbDropdownItem (click)="toggle(subItem)"
               class="d-flex align-items-center justify-content-between c-pointer">
            <div>
              <ng-container
                *ngTemplateOutlet="itemTemplate?itemTemplate:defaultItemTemplate;context:{$implicit:subItem}"></ng-container>
            </div>
            <i [hidden]="!isSelected(subItem)" class="fa fa-check text-success ml-3"></i>
          </div>
        </ng-container>
        <ng-template #ungroupedItem>
          <div ngbDropdownItem (click)="toggle(item)"
               class="d-flex align-items-center justify-content-between c-pointer">
            <div>
              <ng-container
                *ngTemplateOutlet="itemTemplate?itemTemplate:defaultItemTemplate;context:{$implicit:item}"></ng-container>
            </div>
            <i [hidden]="!isSelected(item)" class="fa fa-check text-success ml-3"></i>
          </div>
        </ng-template>
      </ng-container>
      <div *ngIf="loading$|async" ngbDropdownItem class="d-flex align-items-center justify-content-center">
        <span class="spinner-border spinner-border-sm text-muted"></span>
      </div>
    </div>
  </div>
  <ng-template #defaultItemTemplate let-item>
    <span>{{item.name || item || ''}}</span>
  </ng-template>
  <ng-template #defaultSelectionTemplate let-value let-multiple="multiple" let-placeholder="placeholder">
    <span *ngIf="!value||(multiple&&value.length===0);else val">{{placeholder || ''}}</span>
    <ng-template #val>
      <span *ngIf="multiple;else noMultiple">
        <span *ngFor="let v of value;let last=last">
          {{v?.name || v || ''}}<span *ngIf="!last">, </span>
        </span>
      </span>
      <ng-template #noMultiple>
        {{value?.name || value || ''}}
      </ng-template>
    </ng-template>
  </ng-template>
</div>
