<div class="row">
  <div class="col-12">
    <app-image-zoom *ngIf="image$|async as image"
                    [thumbImage]="image.url"
                    [fullImage]="image.url"
                    zoomMode="hover"></app-image-zoom>
  </div>
  <div class="col-12 mt-3">
    <ngb-carousel (slid)="onSlid($event)"
                  [showNavigationArrows]="true"
                  [showNavigationIndicators]="true"
                  [wrap]="true">
      <ng-template *ngFor="let image of images$|async" ngbSlide>
        <div class="text-center">
          <img [src]="image.url" [alt]="image.name" [style.height.px]="thumbnailHeight">
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
