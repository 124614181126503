import { Component, Inject, Input, Optional, Self, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ElementBase } from '../../../core/forms/element-base';

@Component({
  selector: 'app-standard-datepicker',
  templateUrl: './standard-datepicker.component.html',
  styleUrls: ['./standard-datepicker.component.scss']
})
export class StandardDatepickerComponent extends ElementBase<string> {

  static nextId = 0;

  private innerDateValue: NgbDateStruct | null = null;

  innerMinDateValue: NgbDateStruct | any | null = null;
  innerMaxDateValue: NgbDateStruct | any | null = null;

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;

  @Input() id = `standard-datepicker-${StandardDatepickerComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() disabled = false;
  @Input() size: 'sm' | 'lg' | null = null;

  @Input() set minDate(minDate: string) {
    if (!minDate) {
      this.innerMinDateValue = null;
      return;
    }
    const date = moment(minDate, 'YYYY-MM-DD');
    this.innerMinDateValue = {
      year: date.get('year'),
      month: date.get('month') + 1,
      day: date.get('date')
    } as NgbDateStruct;
  }

  @Input() set maxDate(maxDate: string) {
    if (!maxDate) {
      this.innerMaxDateValue = null;
      return;
    }
    const date = moment(maxDate, 'YYYY-MM-DD');
    this.innerMaxDateValue = {
      year: date.get('year'),
      month: date.get('month') + 1,
      day: date.get('date')
    } as NgbDateStruct;
  }

  set date(date: NgbDateStruct | null) {
    if (typeof date !== 'string') {
      this.innerDateValue = date;
    }
    if (!date) {
      this.value = null;
      return;
    }
    const parsedDate = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-M-D');
    this.value = parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null;
  }

  get date(): NgbDateStruct | null {
    return this.innerDateValue;
  }

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  writeValue(value: string): void {
    super.writeValue(value);
    if (!value) {
      this.innerDateValue = null;
      return;
    }
    const date = moment(value, 'YYYY-MM-DD');
    this.innerDateValue = {
      year: date.get('year'),
      month: date.get('month') + 1,
      day: date.get('date')
    } as NgbDateStruct;
  }
}
