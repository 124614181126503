import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { I18nService } from './core/util/i18n.service';
import { SettingsService } from './core/util/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  spinner$: Observable<any> = this.settings.spinner$;

  constructor(private i18n: I18nService, private settings: SettingsService) {
    this.i18n.initialize();
  }
}
