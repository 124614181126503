import { Component, ElementRef, Inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ElementBase } from '../../../core/forms/element-base';
import { Price } from '../../../core/api/common/price';
import { Discount } from '../../../core/api/common/discount';

export type DiscountType = 'PERCENTAGE' | 'AMOUNT';

@Component({
  selector: 'app-standard-discount-input',
  templateUrl: './standard-discount-input.component.html',
  styleUrls: ['./standard-discount-input.component.scss']
})
export class StandardDiscountInputComponent extends ElementBase<Discount> implements OnInit {

  static nextId = 0;

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;
  @ViewChild(NgModel, {static: true, read: ElementRef}) input: ElementRef | null = null;

  @Input() id = `standard-discount-input-${StandardDiscountInputComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() helper: string | null = null;
  @Input() disabled = false;
  @Input() autofocus: boolean | null = null;
  @Input() readonly: boolean | null = null;
  @Input() size: 'sm' | 'lg' | null = null;

  discountType: DiscountType = 'PERCENTAGE';

  discountTitle: string | null = null;
  discountLabel: string | null = null;
  discountValue: number | null = null;
  discountAmount: Price | null = null;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.input?.nativeElement.focus());
    }
  }

  writeValue(value: Discount | null): void {
    super.writeValue(value);
    this.discountTitle = value && value.title || null;
    this.discountLabel = value && value.label || null;
    this.discountValue = value && value.value || null;
    this.discountAmount = value && value.amount || null;
    this.detectDiscountType();
  }

  update(): void {
    this.value = {
      title: this.discountTitle || undefined,
      label: this.discountLabel || undefined,
      value: this.discountType === 'PERCENTAGE' ? (this.discountValue || undefined) : undefined,
      amount: this.discountType === 'AMOUNT' ? (this.discountAmount || undefined) : undefined
    };
  }

  setType(type: DiscountType): void {
    this.discountType = type;
    this.discountValue = null;
    this.discountAmount = null;
    this.update();
  }

  private detectDiscountType(): void {
    this.discountType = this.discountAmount && this.discountAmount.taxAmount ? 'AMOUNT' : 'PERCENTAGE';
  }
}
