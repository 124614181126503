import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import { slideUp } from '../../../core/animations/slide-up';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideUp]
})
export class ActionsComponent implements OnDestroy {

  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.confirmed.complete();
  }

  confirm(): void {
    this.confirmed.next();
  }
}
