<div class="modal-header">
  <h4 class="modal-title">
    <span *ngIf="user?.id;else existingUser" translate>USERS.UPDATE_USER</span>
    <ng-template #existingUser>
      <span translate>USERS.NEW_USER</span>
    </ng-template>
  </h4>
  <button type="button" class="close mr-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-8">
        <app-standard-input formControlName="username"
                            [autofocus]="!user?.id"
                            [label]="'USERS.USERNAME'|translate"></app-standard-input>
      </div>
      <div class="form-group col-4">
        <app-standard-input formControlName="code"
                            [label]="'USERS.CODE'|translate"></app-standard-input>
      </div>
      <div class="form-group col-md-6">
        <app-standard-input formControlName="firstName"
                            [autofocus]="!!user?.id"
                            [label]="'USERS.FIRST_NAME'|translate"></app-standard-input>
      </div>
      <div class="form-group col-md-6">
        <app-standard-input formControlName="lastName"
                            [label]="'USERS.LAST_NAME'|translate"></app-standard-input>
      </div>
      <div class="form-group col-12">
        <app-standard-input formControlName="email"
                            type="email"
                            [label]="'USERS.EMAIL'|translate"></app-standard-input>
      </div>
      <div class="form-group col-12">
        <app-standard-input formControlName="color"
                            type="color"
                            [label]="'USERS.COLOR'|translate"></app-standard-input>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="flex-fill">
    <app-form-errors [form]="form"></app-form-errors>
  </div>
  <button type="button" class="btn btn-outline-dark" (click)="close()">
    <span translate>ACTIONS.CLOSE</span>
  </button>
  <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
    <ng-container *ngIf="saving$|async;else action">
      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
      <span translate>DATA.LOADING</span>
    </ng-container>
    <ng-template #action>
      <span *ngIf="user?.id;else createLabel" translate>ACTIONS.UPDATE</span>
      <ng-template #createLabel>
        <span translate>ACTIONS.CREATE</span>
      </ng-template>
    </ng-template>
  </button>
</div>
