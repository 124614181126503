<div class="fixed-bottom" @slideUp>
  <div class="container">
    <div class="card bg-dark">
      <div class="card-body d-flex align-items-center justify-content-between">
        <div class="text-white">
          <ng-content></ng-content>
        </div>
        <div>
          <button class="btn btn-primary" (click)="confirm()">
            <i class="fa fa-save fa-fw mr-2"></i><span translate>ACTIONS.SAVE</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
