<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<div class="input-group"
     [id]="id"
     [class.input-group-sm]="size==='sm'"
     [class.input-group-lg]="size==='lg'"
     [class.is-invalid]="invalid$|async">
  <input type="number"
         class="form-control"
         autocomplete="off"
         [disabled]="disabled"
         [readonly]="readonly"
         [min]="0"
         [(ngModel)]="taxIncluded" name="taxIncluded"
         (ngModelChange)="taxIncludedChanged($event)"
         [placeholder]="'PRICES.TAX_INCLUDED'|translate">
  <input type="number"
         class="form-control"
         autocomplete="off"
         [disabled]="disabled"
         [readonly]="readonly||true"
         [min]="0"
         [(ngModel)]="taxAmount" name="taxAmount"
         (ngModelChange)="taxAmountChanged($event)"
         [placeholder]="'PRICES.TAX_AMOUNT'|translate">
  <input type="number"
         class="form-control"
         autocomplete="off"
         [disabled]="disabled"
         [readonly]="readonly||true"
         [min]="0"
         [(ngModel)]="taxExcluded" name="taxExcluded"
         (ngModelChange)="taxExcludedChanged($event)"
         [placeholder]="'PRICES.TAX_EXCLUDED'|translate">
</div>
<small *ngIf="helper" class="form-text text-muted">{{helper}}</small>
<div class="invalid-feedback">
  <span *ngFor="let err of failures$|async;let last=last;">{{err}}<span *ngIf="!last">, </span></span>
</div>
