<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title" id="modal-title-default">{{options.title}}</h6>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{options.text}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirm()">Confirm</button>
    <button type="button" class="btn btn-link ml-auto" data-dismiss="modal" (click)="cancel()">Close</button>
  </div>
</div>
