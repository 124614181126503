import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Dropzone, { DropzoneFile } from 'dropzone';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { KeycloakService } from '../../../core/auth/keycloak.service';
import { ElementBase } from '../../../core/forms/element-base';

Dropzone.autoDiscover = false;

@Component({
  selector: 'app-standard-dropzone',
  templateUrl: './standard-dropzone.component.html',
  styleUrls: ['./standard-dropzone.component.scss']
})
export class StandardDropzoneComponent extends ElementBase<File[]> implements OnInit, OnDestroy {

  static nextId = 0;

  private instance: Dropzone | null = null;

  private files: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);

  files$: Observable<File[]> = this.files.asObservable();

  model: NgModel | null = null;

  @Input() id = `standard-dropzone-${StandardDropzoneComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() url: string | null = null;
  @Input() multiple = false;
  @Input() disabled = false;

  @Output() uploading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dropzone', {read: ElementRef, static: true}) dropzone: ElementRef | null = null;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService, private keycloak: KeycloakService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnInit(): void {
    this.instance = new Dropzone(this.dropzone?.nativeElement, {
      url: this.url as string || 'empty-url',
      uploadMultiple: this.multiple,
      autoProcessQueue: false,
      addedfile: (file: Dropzone.DropzoneFile) => {
        if (this.url) {
          this.upload(this.url).subscribe();
          return;
        }
        this.files.next(this.files.getValue().concat([file]));
      }
    });
    if (this.disabled) {
      this.instance.disable();
    }
  }

  ngOnDestroy(): void {
    this.files.complete();
    this.uploading.complete();
    if (this.instance) {
      this.instance.destroy();
    }
  }

  upload(url: string): Observable<any> {
    this.uploading.next(true);
    return this.keycloak.getToken().pipe(
      switchMap(token => {
        return new Observable(observer => {
          if (!this.instance) {
            observer.error('No dropzone initialized');
            return;
          }
          this.instance.options.url = url;
          this.instance.options.headers = {
            Authorization: `Bearer ${token}`
          };
          this.instance.processQueue();
          this.instance.on('success', () => {
            observer.next();
          });
          this.instance.on('complete', () => {
            observer.complete();
          });
          this.instance.on('error', (file: DropzoneFile, error: string | Error) => {
            observer.error(error);
          });
          this.instance.processQueue();
        });
      }),
      finalize(() => this.uploading.next(false))
    );
  }
}
