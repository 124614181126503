import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '../../core/api/common/address';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address | undefined, html: boolean = false): string {
    if (!address) {
      return '';
    }
    const postal = ((address.postalCode || '') + ' ' + (address.postal || '')).trim() || '/';
    if (html) {
      return `${address.line1 || '/'}<br/><small>${postal}</small>`;
    }
    return `${address.line1 || '/'}, ${postal}`;
  }
}
