import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export class ConfirmModalOptions {
  title?: string;
  text?: string;
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  options: ConfirmModalOptions = new ConfirmModalOptions();

  constructor(private modal: NgbActiveModal) {
  }

  cancel(): void {
    this.modal.dismiss();
  }

  confirm(): void {
    this.modal.close();
  }
}
