import { Component, Inject, Input, OnDestroy, Optional, Self } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ElementBase } from '../../../core/forms/element-base';
import { ProductVariant } from '../../../core/api/core/products/product';

export class SimpleVariant {
  name?: string;
  values?: string[];
}

@Component({
  selector: 'app-standard-variants-input',
  templateUrl: './standard-variants-input.component.html',
  styleUrls: ['./standard-variants-input.component.scss']
})
export class StandardVariantsInputComponent extends ElementBase<ProductVariant[]> implements OnDestroy {

  static nextId = 0;

  private variants: BehaviorSubject<SimpleVariant[]> = new BehaviorSubject<SimpleVariant[]>([{}]);

  model: NgModel | null = null;

  @Input() id = `standard-variants-input-${StandardVariantsInputComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() helper: string | null = null;
  @Input() namePlaceholder: string | null = null;
  @Input() valuePlaceholder: string | null = null;
  @Input() disabled = false;
  @Input() readonly: boolean | null = null;

  variants$: Observable<SimpleVariant[]> = this.variants.asObservable();

  added = false;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnDestroy(): void {
    this.variants.complete();
  }

  writeValue(value: ProductVariant[]): void {
    super.writeValue(value);
    this.variants.next((value || [{}]).map(variant => ({
      name: variant.name,
      values: (variant.values || []).map(val => val.value || '')
    })));
  }

  add(): void {
    this.added = true;
    const variants = this.variants.getValue().slice();
    variants.push({});
    this.variants.next([...variants]);
  }

  update(): void {
    this.value = (this.variants.getValue() || []).map(variant => ({
      name: variant.name,
      values: (variant.values || []).map(val => ({value: val}))
    }));
  }
}
