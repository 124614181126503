import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';

import { AlertsService } from '../../util/alerts.service';
import { Product } from '../../api/core/products/product';
import { ProductsService } from '../../api/core/products/products.service';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})
export class ProductModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  saving$: Observable<boolean> = this.saving.asObservable();

  @Input() product: Product | null = null;

  form: FormGroup = this.fb.group({
    title: this.fb.control(null, Validators.required),
    description: this.fb.control(null, [Validators.required, Validators.minLength(2), Validators.maxLength(512)])
  });

  constructor(private modal: NgbActiveModal, private fb: FormBuilder, private alerts: AlertsService,
              private productsService: ProductsService) {
  }

  ngOnInit(): void {
    if (this.product) {
      this.form.patchValue(this.product);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  close(): void {
    this.modal.dismiss('Close click');
  }

  dismiss(): void {
    this.modal.dismiss();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const product = {...this.product, ...this.form.getRawValue()};
    this.saving.next(true);
    if (product.id) {
      this.productsService.update(product.id, product).subscribe(updatedProduct => {
        this.saving.next(false);
        this.modal.close({product: updatedProduct});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot update product', res);
      });
    } else {
      this.productsService.create(product).subscribe(newProduct => {
        this.saving.next(false);
        this.modal.close({product: newProduct});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot create product', res);
      });
    }
  }
}
