import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';

import { ProductImage } from '../../../core/api/core/products/images/product-image';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGalleryComponent implements OnInit {

  private images: BehaviorSubject<ProductImage[]> = new BehaviorSubject<ProductImage[]>([]);
  private image: BehaviorSubject<ProductImage | null> = new BehaviorSubject<ProductImage | null>(null);

  images$: Observable<ProductImage[]> = this.images.asObservable();
  image$: Observable<ProductImage | null> = this.image.asObservable();

  @Input('images')
  set setImages(images: ProductImage[] | undefined) {
    if (!images) {
      this.images.next([]);
      return;
    }
    this.images.next(images);
    this.image.next(images[0]);
  }

  @Input() thumbnailHeight = 200;

  constructor() {
  }

  ngOnInit(): void {
  }

  onSlid(event: NgbSlideEvent): void {
    const id: number = +event.current.replace('ngb-slide-', '');
    this.image.next(this.images.getValue()[id]);
  }
}
