import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthProvider, User } from '../../api/stores/users/user';
import { UsersService } from '../../api/stores/users/users.service';
import { AlertsService } from '../../util/alerts.service';
import { usernameValidator } from '../../forms/validators/username-validator';
import { codeValidator } from '../../forms/validators/code-validator';
import { titleValidator } from '../../forms/validators/title-validator';
import { emailValidator } from '../../forms/validators/email-validator';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  saving$: Observable<boolean> = this.saving.asObservable();

  form: FormGroup;

  @Input() user: User | null = null;
  @Input() disabledFields: { [name: string]: boolean } = {};

  constructor(private modal: NgbActiveModal, fb: FormBuilder, private usersService: UsersService,
              private alerts: AlertsService) {
    this.form = fb.group({
      username: fb.control(null, [usernameValidator as ValidatorFn]),
      code: fb.control(null, [codeValidator as ValidatorFn]),
      firstName: fb.control(null, [titleValidator as ValidatorFn]),
      lastName: fb.control(null, [titleValidator as ValidatorFn]),
      email: fb.control(null, [emailValidator as ValidatorFn, Validators.required]),
      color: fb.control(null)
    });
  }

  ngOnInit(): void {
    if (this.user) {
      this.form.patchValue(this.user);
    }
    if (this.disabledFields) {
      Object.keys(this.disabledFields).forEach(field => this.form.get(field)?.disable());
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  close(): void {
    this.modal.close();
  }

  dismiss(): void {
    this.modal.dismiss();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const user = {...this.user, ...this.form.getRawValue()};
    this.saving.next(true);
    if (this.user && this.user.id) {
      this.usersService.update(user.id, user).subscribe(savedUser => {
        this.saving.next(false);
        this.modal.close({user: savedUser});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot update user', res);
      });
    } else {
      user.authProvider = AuthProvider.KEYCLOAK;
      this.saving.next(true);
      this.usersService.create(user).subscribe(savedUser => {
        this.saving.next(false);
        this.modal.close({user: savedUser});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot create user', res);
      });
    }
  }
}
