import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class NgbDatepickerSettingsService extends NgbDatepickerI18n {

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return moment.localeData(this.locale).weekdaysShort()[weekday === 7 ? 0 : weekday];
  }

  getMonthShortName(month: number): string {
    return moment.localeData(this.locale).monthsShort()[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}. ${date.month}. ${date.year}`;
  }
}
