import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownComponent implements OnDestroy {

  private value: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  value$: Observable<string | null> = this.value.asObservable();
  data$: Observable<any> = this.value$.pipe(map(value => value || ''));

  @Input('value') set setValue(value: string | null) {
    this.value.next(value);
  }

  ngOnDestroy(): void {
    this.value.complete();
  }
}
