import { ControlValueAccessor, NgControl } from '@angular/forms';

export abstract class ValueAccessorBase<T> implements ControlValueAccessor {

  private innerValue: T | null = null;

  private changed = new Array<(value: T | null) => void>();
  private touched = new Array<() => void>();

  protected abstract disabled = false;

  get value(): T | null {
    return this.innerValue;
  }

  set value(value: T | null) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach(f => f(value));
    }
  }

  get name(): string {
    return this.ngControl && this.ngControl.name as string;
  }

  protected constructor(protected ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: T | null): void {
    this.innerValue = value;
  }

  registerOnChange(fn: (value: T | null) => void): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }

  touch(): void {
    this.touched.forEach(f => f());
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
