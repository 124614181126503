import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthInterceptor } from './auth/auth.interceptor';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ProductModalComponent } from './modals/product-modal/product-modal.component';
import { ConfirmModalComponent } from './util-modals/confirm-modal/confirm-modal.component';
import { StoreModalComponent } from './modals/store-modal/store-modal.component';
import { UserModalComponent } from './modals/user-modal/user-modal.component';
import { NgbDatepickerFormatterService } from './ngb-datepicker-formatter.service';
import { NgbDatepickerSettingsService } from './ngb-datepicker-settings.service';
import { AssetsModalComponent } from './util-modals/assets-modal/assets-modal.component';
import { ProductVersionModalComponent } from './modals/product-version-modal/product-version-modal.component';

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    SharedModule,
    NgbModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ProductModalComponent,
    ProductVersionModalComponent,
    ConfirmModalComponent,
    StoreModalComponent,
    UserModalComponent,
    AssetsModalComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: NgbDatepickerI18n, useClass: NgbDatepickerSettingsService},
    {provide: NgbDateParserFormatter, useClass: NgbDatepickerFormatterService}
  ]
})
export class CoreModule {
}
