import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@angular/common';

export interface Breadcrumb {
  title?: string;
  path?: any[] | string | null | undefined;
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {

  @Input() title: string | null = null;
  @Input() breadcrumbs: Breadcrumb[] = [];

  constructor(private location: Location) {
  }

  back(): void {
    this.location.back();
  }
}
