<div #zoomContainer class="image-zoom-container"
     [style.width.px]="thumbWidth"
     [style.height.px]="thumbHeight">

  <img #imageThumbnail class="image-zoom-thumbnail"
       [src]="thumbImage"
       alt="Thumbnail"
       (load)="onThumbImageLoaded()"/>

  <div class="image-zoom-full-container"
       [class.image-zoom-lend-enabled]="enableLens"
       [style.display]="display"
       [style.top.px]="lensTop"
       [style.left.px]="lensLeft"
       [style.width.px]="lensWidth"
       [style.height.px]="lensHeight"
       [style.border-radius.px]="lensBorderRadius">
    <img #fullSizeImage
         class="image-zoom-full"
         [src]="fullImage"
         alt="FullImage"
         (load)="onFullImageLoaded()"
         [style.display]="display"
         [style.top.px]="fullImageTop"
         [style.left.px]="fullImageLeft"
         [style.width.px]="magnifiedWidth"
         [style.height.px]="magnifiedHeight"/>
  </div>
</div>
