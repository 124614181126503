import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthUser, UserService } from '../../auth/user.service';
import { MenuUtilService, RouteInfo } from '../../util/menu-util.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  private sub: Subscription | null = null;

  routes$: Observable<RouteInfo[]> = this.menuUtil.routes$;
  collapsed$: Observable<boolean> = this.menuUtil.collapsed$;
  user$: Observable<AuthUser | null> = this.user.user$;
  store$: Observable<string | null> = this.user$.pipe(map(user => user && user.store || 'NO STORE'));

  constructor(private router: Router, private menuUtil: MenuUtilService, private user: UserService) {
  }

  ngOnInit(): void {
    this.minimizeSidebar();
    this.sub = this.router.events.subscribe(() => this.menuUtil.setCollapsed(true));
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onMouseEnterSidenav(): void {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }

  onMouseLeaveSidenav(): void {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }

  minimizeSidebar(): void {
    const sidenavToggler = document.getElementsByClassName('sidenav-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    const mini = body.classList.contains('g-sidenav-pinned');
    if (mini) {
      body.classList.remove('g-sidenav-pinned');
      body.classList.add('g-sidenav-hidden');
      sidenavToggler.classList.remove('active');
      this.menuUtil.setMini(false);
    } else {
      body.classList.add('g-sidenav-pinned');
      body.classList.remove('g-sidenav-hidden');
      sidenavToggler.classList.add('active');
      this.menuUtil.setMini(true);
    }
  }
}
