<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>

<app-standard-input *ngIf="type==='simple'"
                    [(ngModel)]="val" (ngModelChange)="update()" name="simpleValue"
                    [placeholder]="placeholder||''"></app-standard-input>
<app-standard-textarea *ngIf="type==='text'"
                       [(ngModel)]="val" (ngModelChange)="update()" name="textValue"
                       [placeholder]="placeholder||''"></app-standard-textarea>
<app-standard-rte *ngIf="type==='html'"
                  [(ngModel)]="val" (ngModelChange)="update()" name="htmlValue"
                  [placeholder]="placeholder||''"></app-standard-rte>
<app-standard-markdown *ngIf="type==='markdown'"
                       [(ngModel)]="val" (ngModelChange)="update()" name="htmlValue"
                       [placeholder]="placeholder||''"></app-standard-markdown>

<div class="d-flex align-items-center justify-content-end mt-1">
  <div class="btn-group" role="group">
    <button class="btn btn-secondary btn-sm" *ngFor="let lang of languages$|async"
            (click)="setLanguage(lang)"
            [class.active]="lang.code===language">
      {{lang.code}}
    </button>
  </div>
</div>

<small *ngIf="helper" class="form-text text-muted">{{helper}}</small>
