import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { KeycloakService } from './app/core/auth/keycloak.service';

KeycloakService.init()
  .then(() => {
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
  .catch(error => {
    alert('Cannot initialized App. Please try again later');
    console.error('Cannot initialized Keycloak', error);
  });
