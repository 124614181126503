import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { message } from '../../../core/forms/validate';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent {

  @Input() form: FormGroup | FormArray | null = null;

  private static getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      if (!control) {
        return acc;
      }
      const errors = (control instanceof FormGroup || control instanceof FormArray) ? this.getAllErrors(control) : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }

  private static getFirstError(form: FormGroup | FormArray): { [key: string]: any; } | null {
    const errors = this.getAllErrors(form);
    if (!errors) {
      return null;
    }
    return Object.keys(errors).map(key => errors[key])[0];
  }

  constructor(private translate: TranslateService) {
  }

  errors(): string[] {
    if (!this.form) {
      return [];
    }
    const errors = FormErrorsComponent.getFirstError(this.form);
    if (!errors) {
      return [];
    }
    const messages: { key: string, args?: any }[] = Object.keys(errors || {}).map(k => message(errors, k));
    if (!messages || messages.length === 0) {
      return [];
    }
    const keys: string[] = messages.map(msg => msg.key);
    let args: any = {};
    messages.forEach(msg => {
      args = Object.assign(args, msg.args);
    });
    const translations: { [key: string]: string } = this.translate.instant(keys, args);

    return Object.keys(translations).map((key) => translations[key]);
  }
}
