<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>

<div class="input-group mb-1" *ngFor="let attribute of attributes$|async;let i=index;let last=last">
  <input class="form-control col-4 key-input" autocomplete="off"
         [placeholder]="namePlaceholder||''"
         [(ngModel)]="attribute.name" [name]="id+'name'+i"
         (blur)="update()"
         [readOnly]="readonly"
         [class.last-key-input]="last">
  <input class="form-control col-8" autocomplete="off"
         [placeholder]="valuePlaceholder||''"
         [(ngModel)]="attribute.value" [name]="id+'val'+i"
         (blur)="update()"
         [readOnly]="readonly">
</div>

<small *ngIf="helper" class="form-text text-muted">{{helper}}</small>

<div class="form-row">
  <div class="col-12 d-flex align-items-center justify-content-end">
    <button class="btn btn-sm" type="button" (click)="add()">
      <span class="fa fa-plus fa-fw pr-2"></span><span translate>ACTIONS.ADD</span>
    </button>
  </div>
</div>
