import { Component, ElementRef, Inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ElementBase } from '../../../core/forms/element-base';

@Component({
  selector: 'app-standard-markdown',
  templateUrl: './standard-markdown.component.html',
  styleUrls: ['./standard-markdown.component.scss']
})
export class StandardMarkdownComponent extends ElementBase<string> implements OnInit {

  static nextId = 0;

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;
  @ViewChild(NgModel, {static: true, read: ElementRef}) input: ElementRef | null = null;

  @Input() id = `standard-markdown-${StandardMarkdownComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() helper: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() disabled = false;
  @Input() autofocus: boolean | null = null;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.input?.nativeElement.focus());
    }
  }
}
