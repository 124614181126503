import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent {

  isMobileResolution = false;

  constructor() {
    this.calculateIsMobile();
  }

  @HostListener('window:resize')
  calculateIsMobile(): void {
    this.isMobileResolution = window.innerWidth < 1200;
  }
}
