<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<div class="row">
  <div class="col-4">
    <app-standard-input [(ngModel)]="discountTitle" name="discountTitle"
                        (ngModelChange)="update()"
                        [label]="'DISCOUNTS.TITLE'|translate"
                        [placeholder]="'DISCOUNTS.TITLE_PLACEHOLDER'|translate"></app-standard-input>
  </div>
  <div class="col-6">
    <label class="mb-0 form-control-label" translate>DISCOUNTS.VALUE</label>
    <div class="input-group w-100 flex-nowrap">
      <div class="input-group-prepend" ngbDropdown>
        <button class="btn btn-outline-light h-100" ngbDropdownToggle>
          {{discountType === 'PERCENTAGE' ? '%' : 'EUR'}}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="setType('PERCENTAGE')">%</button>
          <button ngbDropdownItem (click)="setType('AMOUNT')">EUR</button>
        </div>
      </div>
      <app-standard-price-input *ngIf="discountType==='AMOUNT'"
                                [(ngModel)]="discountAmount" name="discountAmount"
                                (ngModelChange)="update()"
                                class="flex-fill"></app-standard-price-input>
      <app-standard-input *ngIf="discountType==='PERCENTAGE'"
                          [(ngModel)]="discountValue" name="discountValue"
                          (ngModelChange)="update()"
                          type="number"
                          [min]="0"
                          [max]="100"
                          class="flex-fill"></app-standard-input>
    </div>
  </div>
  <div class="col-2">
    <app-standard-input [(ngModel)]="discountLabel" name="discountLabel"
                        (ngModelChange)="update()"
                        [label]="'DISCOUNTS.LABEL'|translate"
                        [placeholder]="'DISCOUNTS.LABEL_PLACEHOLDER'|translate"></app-standard-input>
  </div>
</div>
