<label [for]="id" *ngIf="label && type!=='hidden'" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<input class="form-control"
       [class.form-control-sm]="size==='sm'"
       [class.form-control-lg]="size==='lg'"
       autocomplete="off"
       [id]="id"
       [type]="type"
       [placeholder]="placeholder||''"
       [disabled]="disabled"
       [readonly]="readonly"
       [min]="min"
       [max]="max"
       [(ngModel)]="value"
       [name]="name"
       [class.is-invalid]="invalid$|async">
<small *ngIf="helper" class="form-text text-muted">{{helper}}</small>
<div class="invalid-feedback">
  <span *ngFor="let err of failures$|async;let last=last;">{{err}}<span *ngIf="!last">, </span></span>
</div>
