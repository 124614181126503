import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Product } from '../../../core/api/core/products/product';

@Component({
  selector: 'app-product-seo-preview',
  templateUrl: './product-seo-preview.component.html',
  styleUrls: ['./product-seo-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSeoPreviewComponent {

  private product: BehaviorSubject<Product | null> = new BehaviorSubject<Product | null>(null);

  product$: Observable<Product | null> = this.product.asObservable();

  @Input('product') set setProduct(product: Product | null) {
    this.product.next(product);
  }
}
