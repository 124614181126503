import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Language } from '../util/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService implements OnDestroy {

  private languages: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([{
      name: 'ENGLISH',
      flag: 'gb',
      code: 'en',
    }, {
      name: 'SLOVENIAN',
      flag: 'si',
      code: 'sl',
    }]
  );
  private taxRate: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(22);

  languages$: Observable<Language[]> = this.languages.asObservable();
  taxRate$: Observable<number | null> = this.taxRate.asObservable();

  constructor() {
  }

  ngOnDestroy(): void {
    this.languages.complete();
    this.taxRate.complete();
  }

  getTranslations(seed: any): {} {
    const languages = this.languages.getValue();
    const tmp: any = {};
    languages.forEach(lang => {
      if (!lang || !lang.code) {
        return;
      }
      tmp[lang.code] = seed && seed[lang.code] || null;
    });
    return tmp;
  }

  getDefaultLanguageCode(): string {
    return this.languages.getValue()[0].code as string;
  }

  getDefaultLanguage(): Language {
    return this.languages.getValue()[0];
  }

  getTaxRate(): number | null {
    return this.taxRate.getValue();
  }

  getCurrency(): string | null {
    return 'EUR';
  }
}
