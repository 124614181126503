import { StandardAuditableDto } from '../../standard-api';
import { Store } from '../stores/store';

export enum AuthProvider {
  KEYCLOAK = 'KEYCLOAK', NONE = 'NONE'
}

export interface User extends StandardAuditableDto {
  username?: string;
  code?: string;
  firstName?: string;
  lastName?: string;
  color?: string;
  email?: string;
  authProvider?: AuthProvider;
  authProviderId?: string;
  store?: Store;
}
