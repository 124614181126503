import { Component, ElementRef, Inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Decimal } from 'decimal.js';

import { ElementBase } from '../../../core/forms/element-base';
import { Price } from '../../../core/api/common/price';
import { StoreService } from '../../../core/auth/store.service';

@Component({
  selector: 'app-standard-price-input',
  templateUrl: './standard-price-input.component.html',
  styleUrls: ['./standard-price-input.component.scss']
})
export class StandardPriceInputComponent extends ElementBase<Price> implements OnInit {

  static nextId = 0;

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;
  @ViewChild(NgModel, {static: true, read: ElementRef}) input: ElementRef | null = null;

  @Input() id = `standard-price-input-${StandardPriceInputComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() helper: string | null = null;
  @Input() disabled = false;
  @Input() autofocus: boolean | null = null;
  @Input() readonly: boolean | null = null;
  @Input() size: 'sm' | 'lg' | null = null;

  taxIncluded: number | null = null;
  taxAmount: number | null = null;
  taxExcluded: number | null = null;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService, private store: StoreService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.input?.nativeElement.focus());
    }
  }

  writeValue(value: Price | null): void {
    super.writeValue(value);
    this.taxIncluded = value && value.taxIncluded || null;
    this.taxAmount = value && value.taxAmount || null;
    this.taxExcluded = value && value.taxExcluded || null;
  }

  taxIncludedChanged(value: number): void {
    const taxRate = this.store.getTaxRate();
    this.value = {...this.value || {}};
    this.value.taxIncluded = value;
    if (!taxRate) {
      return;
    }
    const taxRatePlusOne = 1 + (taxRate / 100);
    this.taxExcluded = this.value.taxExcluded = new Decimal(this.value.taxIncluded || 0)
      .times(1 / taxRatePlusOne)
      .toDecimalPlaces(2)
      .toNumber();
    this.taxAmount = this.value.taxAmount = new Decimal(this.value.taxIncluded || 0)
      .times(1 / taxRatePlusOne)
      .times(taxRate / 100)
      .toDecimalPlaces(2)
      .toNumber();
    this.update();
  }

  taxAmountChanged(value: number): void {
    const taxRate = this.store.getTaxRate();
    this.value = {...this.value || {}};
    this.value.taxAmount = value;
    if (!taxRate) {
      return;
    }
    const taxRatePlusOne = 1 + (taxRate / 100);
    this.taxExcluded = this.value.taxExcluded = new Decimal(this.value.taxAmount || 0)
      .dividedBy(taxRate / 100)
      .toDecimalPlaces(2)
      .toNumber();
    this.taxIncluded = this.value.taxIncluded = new Decimal(this.value.taxAmount || 0)
      .dividedBy(taxRate / 100).times(taxRatePlusOne)
      .toDecimalPlaces(2)
      .toNumber();
    this.update();
  }

  taxExcludedChanged(value: number): void {
    const taxRate = this.store.getTaxRate();
    this.value = {...(this.value || {})};
    this.value.taxExcluded = value;
    if (!taxRate) {
      return;
    }
    const taxRatePlusOne = 1 + (taxRate / 100);
    this.taxAmount = this.value.taxAmount = new Decimal(this.value.taxExcluded || 0)
      .times(taxRate / 100)
      .toDecimalPlaces(2)
      .toNumber();
    this.taxIncluded = this.value.taxIncluded = new Decimal(this.value.taxExcluded || 0)
      .times(taxRatePlusOne)
      .toDecimalPlaces(2)
      .toNumber();
    this.update();
  }

  private update(): void {
    this.value = {
      taxIncluded: this.taxIncluded || undefined,
      taxAmount: this.taxAmount || undefined,
      taxExcluded: this.taxExcluded || undefined,
      currency: this.value?.currency
    };
  }
}
