<div class="container-fluid">
  <footer class="footer">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ now | date: "yyyy" }}
          <a href="https://xbranch.io" class="font-weight-bold ml-1" target="_blank">xBranch</a>
        </div>
      </div>
      <div class="col-xl-6">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
          <li class="nav-item">
            <a href="https://xbranch.io" class="nav-link" target="_blank">xBranch</a>
          </li>
          <li class="nav-item">
            <a href="https://xbranch.io/about" class="nav-link" target="_blank">About Us</a>
          </li>
          <li class="nav-item">
            <a href="https://blog.xbranch.io/about" class="nav-link" target="_blank">Blog</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div>
