import { Component, Inject, Input, Optional, Self, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { ElementBase } from '../../../core/forms/element-base';

@Component({
  selector: 'app-standard-datetimepicker',
  templateUrl: './standard-datetimepicker.component.html',
  styleUrls: ['./standard-datetimepicker.component.scss']
})
export class StandardDatetimepickerComponent extends ElementBase<string> {

  static nextId = 0;

  private innerDateValue: NgbDateStruct | null = null;
  private innerTimeValue: NgbTimeStruct | null = null;

  innerMinDateValue: NgbDateStruct | any | null = null;
  innerMaxDateValue: NgbDateStruct | any | null = null;

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;

  @Input() id = `standard-datetimepicker-${StandardDatetimepickerComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() disabled = false;
  @Input() seconds = false;
  @Input() spinners = true;
  @Input() hourStep = 1;
  @Input() minuteStep = 15;
  @Input() secondStep = 30;
  @Input() defaultToNearestStep = false;
  @Input() size: 'sm' | 'lg' | null = null;

  @Input() set minDate(minDate: string) {
    if (!minDate) {
      this.innerMinDateValue = null;
      return;
    }
    const date = moment(minDate, 'YYYY-MM-DD');
    this.innerMinDateValue = {
      year: date.get('year'),
      month: date.get('month') + 1,
      day: date.get('date')
    } as NgbDateStruct;
  }

  @Input() set maxDate(maxDate: string) {
    if (!maxDate) {
      this.innerMaxDateValue = null;
      return;
    }
    const date = moment(maxDate, 'YYYY-MM-DD');
    this.innerMaxDateValue = {
      year: date.get('year'),
      month: date.get('month') + 1,
      day: date.get('date')
    } as NgbDateStruct;
  }

  set date(date: NgbDateStruct | null) {
    this.innerDateValue = date;
    this.innerTimeValue = this.innerTimeValue || this.defaultTime();
    this.update();
  }

  get date(): NgbDateStruct | null {
    return this.innerDateValue;
  }

  set time(date: NgbTimeStruct | null) {
    this.innerTimeValue = date;
    this.update();
  }

  get time(): NgbTimeStruct | null {
    return this.innerTimeValue;
  }

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  writeValue(value: string): void {
    super.writeValue(value);
    if (!value) {
      this.innerDateValue = null;
      this.innerTimeValue = null;
      return;
    }
    const date = moment(value, 'YYYY-MM-DDTHH:mm:ss');
    this.innerDateValue = {
      year: date.get('year'),
      month: date.get('month') + 1,
      day: date.get('date')
    } as NgbDateStruct;
    this.innerTimeValue = {
      hour: date.get('hours'),
      minute: date.get('minutes'),
      second: date.get('seconds')
    } as NgbTimeStruct;
  }

  private update(): void {
    if (!this.innerDateValue) {
      this.value = null;
      return;
    }
    const date = this.innerDateValue;
    const time = this.innerTimeValue || this.defaultTime();
    this.value = moment(`${date.year}-${date.month}-${date.day}T${time.hour}:${time.minute}:${time.second}`, 'YYYY-M-DTH:m:s')
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  private defaultTime(): NgbTimeStruct {
    if (!this.defaultToNearestStep) {
      return {hour: 0, minute: 0, second: 0} as NgbTimeStruct;
    }
    const now = moment();
    let hour: any = Math.ceil(now.hour() / this.hourStep) * this.hourStep;
    let minute: any = Math.ceil(now.minute() / this.minuteStep) * this.minuteStep;
    if (minute >= 60) {
      hour = hour + (minute / 60);
      minute = minute % 60;
    }
    return {hour, minute, second: 0} as NgbTimeStruct;
  }
}
