<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>


<div class="input-group mb-1 w-100 align-content-stretch flex-nowrap"
     *ngFor="let variant of variants$|async;let i=index;let last=last">
  <app-standard-input [placeholder]="namePlaceholder||''"
                      [(ngModel)]="variant.name" [name]="id+'name'+i"
                      (ngModelChange)="update()"
                      class="p-0 col-4"
                      [autofocus]="added&&last"></app-standard-input>
  <app-standard-tags-input [placeholder]="valuePlaceholder||''"
                           [(ngModel)]="variant.values" [name]="id+'val'+i"
                           (ngModelChange)="update()"
                           class="p-0 col-8"></app-standard-tags-input>
</div>

<small *ngIf="helper" class="form-text text-muted">{{helper}}</small>

<div class="form-row">
  <div class="col-12 d-flex align-items-center justify-content-end">
    <button class="btn btn-sm" type="button" (click)="add()">
      <span class="fa fa-plus fa-fw pr-2"></span><span translate>ACTIONS.ADD</span>
    </button>
  </div>
</div>
