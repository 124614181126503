<div class="modal-header">
  <h4 class="modal-title" translate>STORES.STORE</h4>
  <button type="button" class="close mr-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" role="form">
    <div class="form-row">
      <div class="form-group col-12">
        <app-standard-input formControlName="title"
                            [label]="'STORES.TITLE'|translate"></app-standard-input>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="flex-fill">
    <app-form-errors [form]="form"></app-form-errors>
  </div>
  <button type="button" class="btn btn-outline-dark" (click)="close()">
    <span translate>ACTIONS.CLOSE</span>
  </button>
  <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
    <ng-container *ngIf="saving$|async;else action">
      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
      <span translate>DATA.SAVING</span>
    </ng-container>
    <ng-template #action>
      <span translate>ACTIONS.SAVE</span>
    </ng-template>
  </button>
</div>
