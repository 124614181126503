import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';

import { StandardFilter } from '../../../core/api/standard-api';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit, OnDestroy, OnChanges {

  private initialPage: number | null = null;

  @Input() filter: StandardFilter = {} as StandardFilter;
  @Input() count: number | null = 0;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('pagination', {read: NgbPagination, static: true}) pagination?: NgbPagination;

  get collectionSize(): number {
    return this.count || 0;
  }

  ngOnInit(): void {
    this.initialPage = (this.filter?.offset / this.filter?.limit) + 1;
  }

  ngOnDestroy(): void {
    this.pageChange.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // apply in timeout (value count is applied after this cycle)
    setTimeout(() => {
      // set initial page (issue if initial page is grater than 1)
      if (changes.count && changes.count.currentValue) {
        if (!this.pagination) {
          return;
        }
        this.pagination.selectPage((this.filter.offset / this.filter.limit) + 1);
        setTimeout(() => this.initialPage = null);
      }
    });
  }

  pageChanged(page: number): void {
    if (this.initialPage !== null) {
      return;
    }
    this.pageChange.emit(page);
  }
}
