<span class="badge badge-dot mr-4" *ngIf="product as p">
  <ng-container *ngIf="!p.latestVersion&&!p.currentVersion">
     <i class="bg-warning"></i><span translate>PRODUCTS.STATUSES.MISSING</span>
  </ng-container>
  <ng-container *ngIf="p.latestVersion&&p.currentVersion&&p.latestVersion.id===p.currentVersion.id">
     <i class="bg-success"></i><span translate>PRODUCTS.STATUSES.ACTIVE</span>
  </ng-container>
  <ng-container *ngIf="p.latestVersion&&p.currentVersion&&p.latestVersion.id!==p.currentVersion.id">
     <i class="bg-info"></i><span translate>PRODUCTS.STATUSES.DEPRECATED</span>
  </ng-container>
   <ng-container *ngIf="p.latestVersion&&!p.currentVersion&&p.latestVersion.validTo&&p.latestVersion.validTo<now">
     <i class="bg-danger"></i><span translate>PRODUCTS.STATUSES.ARCHIVED</span>
  </ng-container>
   <ng-container *ngIf="p.latestVersion&&!p.currentVersion&&p.latestVersion.validFrom&&p.latestVersion.validFrom>now">
     <i class="bg-dark"></i><span translate>PRODUCTS.STATUSES.DRAFT</span>
  </ng-container>
</span>
<br/>
<span class="text-muted" *ngIf="product as p">
  <ng-container *ngIf="!p.latestVersion&&!p.currentVersion">
     No version defined
  </ng-container>
  <ng-container *ngIf="p.latestVersion&&p.currentVersion&&p.latestVersion.id!==p.currentVersion.id">
     Active but one or more versions defined
  </ng-container>
   <ng-container *ngIf="p.latestVersion&&!p.currentVersion&&p.latestVersion.validTo&&p.latestVersion.validTo<now">
     Expired: {{p.latestVersion.validTo|date}}
  </ng-container>
   <ng-container *ngIf="p.latestVersion&&!p.currentVersion&&p.latestVersion.validFrom&&p.latestVersion.validFrom>now">
     Available from: {{p.latestVersion.validFrom|date}}
  </ng-container>
</span>
