import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MenuUtilService } from '../../util/menu-util.service';
import { SettingsService } from '../../util/settings.service';
import { AuthUser, UserService } from '../../auth/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  private readonly sub: Subscription;

  private focused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  focused$: Observable<boolean> = this.focused.asObservable();
  opened$: Observable<boolean> = this.menuUtil.opened$;
  background$: Observable<string> = this.settings.background$;
  navbarDark$: Observable<boolean> = this.settings.navbarDark$;
  user$: Observable<AuthUser | null> = this.user.user$;

  constructor(private location: Location, private element: ElementRef, private router: Router, private menuUtil: MenuUtilService,
              private settings: SettingsService, private user: UserService) {
    this.sub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (window.innerWidth < 1200) {
        document.body.classList.remove('g-sidenav-pinned');
        document.body.classList.add('g-sidenav-hidden');
        this.menuUtil.setOpened(false);
      }
    });
  }

  ngOnInit(): void {
    this.openSidebar();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.focused.complete();
  }

  setFocused(focused: boolean): void {
    this.focused.next(focused);
  }

  openSearch(): void {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(() => {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  }

  closeSearch(): void {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  }

  openSidebar(): void {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.menuUtil.setOpened(false);
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.menuUtil.setOpened(true);
    }
  }

  toggleSidenav(): void {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.menuUtil.setOpened(false);
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.menuUtil.setOpened(true);
    }
  }

  logout(): void {
    this.user.logout();
  }
}
