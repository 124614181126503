<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<div class="form-control d-flex align-items-center justify-content-start flex-wrap h-auto"
     [id]="id"
     [class.form-control-sm]="size==='sm'"
     [class.form-control-lg]="size==='lg'"
     [class.is-invalid]="invalid$|async">
  <div *ngFor="let tag of value" class="badge badge-pill mr-1 rounded-sm"
       [class.badge-primary]="tag!==selected"
       [class.badge-secondary]="tag===selected"
       style="font-size:100%;">
    {{tag}}<i class="fa fa-times c-pointer fa-fw ml-1" (click)="remove(tag)"></i>
  </div>
  <input [(ngModel)]="tag" name="tag" class="flex-grow-1"
         (keydown.enter)="onEnter($event)"
         (keydown.backspace)="onBackspace()"
         (keydown.arrowLeft)="onArrowLeft()"
         (keydown.arrowRight)="onArrowRight()"
         (focusin)="clearSelected()"
         (blur)="tryCreateTag()"
         #instance="ngbTypeahead"
         [ngbTypeahead]="search"
         (selectItem)="tryCreateTagAsync()"
         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         [placeholder]="placeholder||('GENERAL.TAGS_PLACEHOLDER'|translate)"
         [disabled]="disabled"/>
</div>
<div class="invalid-feedback">
  <span *ngFor="let err of failures$|async;let last=last;">{{err}}<span *ngIf="!last">, </span></span>
</div>
