import { Component, Inject, Input, OnDestroy, Optional, Self } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ElementBase } from '../../../core/forms/element-base';

export class NamedValue {
  name?: string | null = null;
  value?: string | null = null;
}

@Component({
  selector: 'app-standard-attributes-input',
  templateUrl: './standard-attributes-input.component.html',
  styleUrls: ['./standard-attributes-input.component.scss']
})
export class StandardAttributesInputComponent extends ElementBase<any | NamedValue[]> implements OnDestroy {

  static nextId = 0;

  private attributes: BehaviorSubject<NamedValue[]> = new BehaviorSubject<NamedValue[]>([{name: null, value: null}]);

  model: NgModel | null = null;

  @Input() id = `standard-attributes-input-${StandardAttributesInputComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() helper: string | null = null;
  @Input() namePlaceholder: string | null = null;
  @Input() valuePlaceholder: string | null = null;
  @Input() disabled = false;
  @Input() readonly: boolean | null = null;
  @Input() type: 'object' | 'array' = 'array';

  attributes$: Observable<NamedValue[]> = this.attributes.asObservable();

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnDestroy(): void {
    this.attributes.complete();
  }

  writeValue(value: any | NamedValue[]): void {
    super.writeValue(value);
    if (this.type === 'array' && value && value.length > 0) {
      this.attributes.next(value);
    } else if (this.type === 'object' && value) {
      this.attributes.next(Object.keys(value).map(k => ({name: k, value: value[k] || null})));
    } else {
      this.attributes.next([{name: null, value: null}]);
    }
  }

  add(): void {
    const attributes = this.attributes.getValue().slice();
    attributes.push({name: null, value: null});
    this.attributes.next(attributes);
    this.focusLatestKey();
  }

  update(): void {
    const attributes = this.attributes.getValue();
    if (this.type === 'array') {
      this.value = attributes;
      return;
    }
    const val: any = {};
    attributes.forEach((attr: NamedValue) => {
      if (!attr || !attr.name) {
        return;
      }
      val[attr.name] = attr.value || null;
    });
    this.value = val;
  }

  private focusLatestKey(): void {
    setTimeout(() => {
      const lastKeyInput = document.querySelector('.last-key-input');
      if (!lastKeyInput) {
        return;
      }
      (lastKeyInput as HTMLElement).focus();
    }, 99);
  }
}
