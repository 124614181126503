import { FormControl, NgControl, NgModel } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ValueAccessorBase } from './value-accessor-base';
import { AsyncValidatorArray, message, validate, ValidatorArray } from './validate';

export abstract class ElementBase<T> extends ValueAccessorBase<T> {

  protected abstract model: NgModel | null = null;

  protected constructor(private validators: ValidatorArray, private asyncValidators: AsyncValidatorArray, ngControl: NgControl,
                        protected translate: TranslateService) {
    super(ngControl);
  }

  required$(): Observable<boolean> {
    const validator = this.ngControl && this.ngControl.control && this.ngControl.control.validator;
    const errors = validator && validator(new FormControl());
    return of(errors && errors.hasOwnProperty('required') || false);
  }

  protected validateInnerModel$(): Observable<any> {
    return validate(this.validators, this.asyncValidators)(this.model?.control || null);
  }

  protected errorsFromOuterModel$(): Observable<any> {
    if (this.ngControl == null || this.ngControl.errors == null) {
      return of(null);
    }
    return of(this.ngControl.errors);
  }

  get invalid$(): Observable<boolean> {
    return combineLatest([this.validateInnerModel$(), this.errorsFromOuterModel$()]).pipe(
      filter(() => this.model?.dirty || this.model?.touched || this.ngControl.touched || this.ngControl.dirty || false),
      map(([innerModelErrors, outerModelErrors]) => {
        const errors = Object.assign(innerModelErrors || {}, outerModelErrors || {});
        return Object.keys(errors || {}).length > 0;
      })
    );
  }

  get failures$(): Observable<string[]> {
    return combineLatest([this.validateInnerModel$(), this.errorsFromOuterModel$()]).pipe(
      filter(() => this.model?.dirty || this.model?.touched || this.ngControl.touched || this.ngControl.dirty || false),
      map(([innerModelErrors, outerModelErrors]) => {
        const errors = Object.assign(innerModelErrors || {}, outerModelErrors || {});
        return Object.keys(errors || {}).map(k => message(errors, k));
      }),
      mergeMap((messages: { key: string, args?: any }[]) => {
        if (!messages || messages.length === 0) {
          return of([]);
        }
        const keys: string[] = messages.map(msg => msg.key);
        let args: any = {};
        messages.forEach(msg => {
          args = Object.assign(args, msg.args);
        });
        return this.translate.get(keys, args).pipe(
          map((translations: { [key: string]: string }) => Object.keys(translations).map((key) => translations[key]))
        );
      })
    );
  }
}
