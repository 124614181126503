import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icon: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}

export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuUtilService implements OnDestroy {

  private routes: BehaviorSubject<RouteInfo[]> = new BehaviorSubject<RouteInfo[]>([
    {path: '/platform/dashboard', title: 'Dashboard', type: 'link', icon: 'fa-tachometer-alt text-danger'},
    {path: '/platform/stores', title: 'Stores', type: 'link', icon: 'fa-store text-orange'},
    {path: '/platform/users', title: 'Users', type: 'link', icon: 'fa-users text-blue'},
    {path: '/platform/orders', title: 'Orders', type: 'link', icon: 'fa-receipt text-info'},
    {path: '/platform/customers', title: 'Customers', type: 'link', icon: 'fa-handshake text-pink'},
    {path: '/platform/products', title: 'Products', type: 'link', icon: 'fa-cubes text-purple'},
    {path: '/platform/campaigns', title: 'Campaigns', type: 'link', icon: 'fa-mail-bulk text-red'}
  ]);
  private opened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private collapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private mini: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  routes$: Observable<RouteInfo[]> = this.routes.asObservable();
  opened$: Observable<boolean> = this.opened.asObservable();
  collapsed$: Observable<boolean> = this.collapsed.asObservable();
  mini$: Observable<boolean> = this.mini.asObservable();

  constructor() {
  }

  ngOnDestroy(): void {
    this.routes.complete();
    this.opened.complete();
    this.collapsed.complete();
    this.mini.complete();
  }

  setOpened(opened: boolean): void {
    this.opened.next(opened);
  }

  setCollapsed(collapsed: boolean): void {
    this.collapsed.next(collapsed);
  }

  setMini(mini: boolean): void {
    this.mini.next(mini);
  }
}
