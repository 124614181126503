<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<div class="input-group">
  <div class="form-control d-flex align-items-center justify-content-start"
       [class.form-control-sm]="size==='sm'"
       [class.form-control-lg]="size==='lg'"
       [class.is-invalid]="invalid$|async">
    <input ngbDatepicker #d="ngbDatepicker"
           autocomplete="off"
           [id]="id"
           [placeholder]="placeholder || 'DD. MM. YYYY'"
           [minDate]="innerMinDateValue"
           [maxDate]="innerMaxDateValue"
           container="body"
           [firstDayOfWeek]="1"
           [disabled]="disabled"
           [footerTemplate]="timePicker"
           [(ngModel)]="date">
    <ngb-timepicker class="embedded-timepicker" [(ngModel)]="time"
                    [seconds]="seconds"
                    [size]="'small'"
                    [spinners]="false"
                    [hourStep]="hourStep"
                    [minuteStep]="minuteStep"
                    [secondStep]="secondStep"
                    [disabled]="disabled"></ngb-timepicker>
  </div>
  <div class="input-group-append c-pointer" (click)="!disabled&&d.toggle()">
    <span class="input-group-text">
      <i class="fa fa-fw fa-calendar-alt"></i>
    </span>
  </div>
</div>
<div class="invalid-feedback">
  <span *ngFor="let err of failures$|async;let last=last;">{{err}}<span *ngIf="!last">, </span></span>
</div>
<ng-template #timePicker>
  <ngb-timepicker [(ngModel)]="time"
                  [seconds]="seconds"
                  [size]="'small'"
                  [spinners]="spinners"
                  [hourStep]="hourStep"
                  [minuteStep]="minuteStep"
                  [secondStep]="secondStep"></ngb-timepicker>
</ng-template>
