import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './core/layouts/admin-layout/admin-layout.component';

const routes: Routes = [
  {path: '', redirectTo: 'platform', pathMatch: 'full'},
  {
    path: 'platform', component: AdminLayoutComponent, children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', loadChildren: () => import('src/app/dashboard/dashboard.module').then(m => m.DashboardModule)},
      {path: 'stores', loadChildren: () => import('./stores/stores.module').then(m => m.StoresModule)},
      {path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
      {path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)},
      {path: 'campaigns', loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule)},
      {path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)},
      {path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)},
      {path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)},
      {path: 'user-profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
