<div class="d-flex align-items-center justify-content-between">
  <div class="d-flex align-items-center">
    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="filter.limit"
            (ngModelChange)="pageChanged(1)">
      <option [ngValue]="10">10</option>
      <option [ngValue]="15">15</option>
      <option [ngValue]="25">25</option>
      <option [ngValue]="50">50</option>
    </select>
    <span class="text-muted"><span class="ml-2 mr-2">/</span>{{count}}</span>
  </div>
  <ngb-pagination #pagination
                  [collectionSize]="collectionSize"
                  [page]="(filter.offset/filter.limit)+1"
                  [pageSize]="filter.limit"
                  [boundaryLinks]="true"
                  [maxSize]="3"
                  [rotate]="true"
                  (pageChange)="pageChanged($event)"></ngb-pagination>
</div>
