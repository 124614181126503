import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';

import { AlertsService } from '../../util/alerts.service';
import { Product } from '../../api/core/products/product';
import { ProductVersion } from '../../api/core/products/versions/product-version';
import { ProductVersionsService } from '../../api/core/products/versions/product-versions.service';
import { StoreService } from '../../auth/store.service';

@Component({
  selector: 'app-product-version-modal',
  templateUrl: './product-version-modal.component.html',
  styleUrls: ['./product-version-modal.component.scss']
})
export class ProductVersionModalComponent implements OnInit, OnDestroy {

  private saving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  saving$: Observable<boolean> = this.saving.asObservable();

  @Input() product: Product | null = null;
  @Input() version: ProductVersion | null = null;

  form: FormGroup = this.fb.group({
    validFrom: this.fb.control(null, Validators.required),
    validTo: this.fb.control(null, Validators.required),
    price: this.fb.control(null, Validators.required),
    price_unit: this.fb.control(null),
    currency: this.fb.control(this.store.getCurrency(), Validators.required)
  });

  constructor(private modal: NgbActiveModal, private fb: FormBuilder, private alerts: AlertsService,
              private productVersionsService: ProductVersionsService, private store: StoreService) {
  }

  ngOnInit(): void {
    if (this.version) {
      this.form.patchValue(this.version);
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  close(): void {
    this.modal.dismiss('Close click');
  }

  dismiss(): void {
    this.modal.dismiss();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (!this.product || !this.product.id) {
      return;
    }
    const version = {...this.version, ...this.form.getRawValue()};
    this.saving.next(true);
    if (version.id) {
      this.productVersionsService.update(this.product.id, version.id, version).subscribe(updatedVersion => {
        this.saving.next(false);
        this.modal.close({version: updatedVersion});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot update product version', res);
      });
    } else {
      this.productVersionsService.create(this.product.id, version).subscribe(newVersion => {
        this.saving.next(false);
        this.modal.close({version: newVersion});
      }, (res: HttpErrorResponse) => {
        this.saving.next(false);
        this.alerts.httpError('Cannot create product version', res);
      });
    }
  }
}
