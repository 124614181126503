import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { User } from './user';
import { ListData, StandardApi, StandardFilter } from '../../standard-api';

export class UsersFilter extends StandardFilter {
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService extends StandardApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: UsersFilter): Observable<ListData<User>> {
    return this.list(`${environment.storesSrv}/users`, {
      params: this.params(filter, [])
    });
  }

  create(user: User): Observable<User> {
    return this.http.post<User>(`${environment.storesSrv}/users`, user);
  }

  findById(id: string): Observable<User> {
    return this.http.get<User>(`${environment.storesSrv}/users/${id}`);
  }

  update(id: string, user: User): Observable<User> {
    return this.http.put<User>(`${environment.storesSrv}/users/${id}`, user);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.storesSrv}/users/${id}`);
  }
}
