import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbTimepickerModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MarkdownModule } from 'ngx-markdown';

import { AvatarGeneratorComponent } from './components/avatar-generator/avatar-generator.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { StandardInputComponent } from './components/standard-input/standard-input.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { StandardDropzoneComponent } from './components/standard-dropzone/standard-dropzone.component';
import { StandardRteComponent } from './components/standard-rte/standard-rte.component';
import { AddressPipe } from './pipes/address.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { SrcErrDirective } from './directives/src-err.directive';
import { StandardTextareaComponent } from './components/standard-textarea/standard-textarea.component';
import { StandardSelectComponent } from './components/standard-select/standard-select.component';
import { StandardTagsInputComponent } from './components/standard-tags-input/standard-tags-input.component';
import { StandardAttributesInputComponent } from './components/standard-attributes-input/standard-attributes-input.component';
import { StandardDatepickerComponent } from './components/standard-datepicker/standard-datepicker.component';
import { StandardDatetimepickerComponent } from './components/standard-datetimepicker/standard-datetimepicker.component';
import { StandardCheckboxComponent } from './components/standard-checkbox/standard-checkbox.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { ImageZoomComponent } from './components/image-zoom/image-zoom.component';
import { JsonPreviewComponent } from './components/json-preview/json-preview.component';
import { ProductStatusComponent } from './components/product-status/product-status.component';
import { StandardLanguageBasedInputComponent } from './components/standard-language-based-input/standard-language-based-input.component';
import { StandardMarkdownComponent } from './components/standard-markdown/standard-markdown.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { StandardPriceInputComponent } from './components/standard-price-input/standard-price-input.component';
import { StandardDiscountInputComponent } from './components/standard-discount-input/standard-discount-input.component';
import { StandardSimpleDiscountInputComponent } from './components/standard-simple-discount-input/standard-simple-discount-input.component';
import { ProductSeoPreviewComponent } from './components/product-seo-preview/product-seo-preview.component';
import { StandardVariantsInputComponent } from './components/standard-variants-input/standard-variants-input.component';
import { ActionsComponent } from './components/actions/actions.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TextFieldModule,
    TranslateModule,
    PerfectScrollbarModule,
    MarkdownModule,
    // bootstrap
    NgbPaginationModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgbPopoverModule,
    NgbCollapseModule,
    NgbCarouselModule
  ],
  declarations: [
    // components
    AvatarGeneratorComponent,
    TablePaginationComponent,
    PageHeaderComponent,
    FormErrorsComponent,
    StandardInputComponent,
    StandardTextareaComponent,
    StandardCheckboxComponent,
    StandardSelectComponent,
    StandardTagsInputComponent,
    StandardAttributesInputComponent,
    StandardVariantsInputComponent,
    StandardDatepickerComponent,
    StandardDatetimepickerComponent,
    StandardRteComponent,
    StandardMarkdownComponent,
    StandardDropzoneComponent,
    StandardLanguageBasedInputComponent,
    StandardPriceInputComponent,
    StandardDiscountInputComponent,
    StandardSimpleDiscountInputComponent,
    ImageGalleryComponent,
    ImageZoomComponent,
    JsonPreviewComponent,
    MarkdownComponent,
    ProductStatusComponent,
    ProductSeoPreviewComponent,
    ActionsComponent,
    // directives
    SrcErrDirective,
    // pipes
    AddressPipe,
    EmptyPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PerfectScrollbarModule,
    MarkdownModule,
    // bootstrap
    NgbPaginationModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgbPopoverModule,
    NgbCollapseModule,
    NgbCarouselModule,
    // components
    AvatarGeneratorComponent,
    TablePaginationComponent,
    PageHeaderComponent,
    FormErrorsComponent,
    StandardInputComponent,
    StandardTextareaComponent,
    StandardCheckboxComponent,
    StandardSelectComponent,
    StandardTagsInputComponent,
    StandardAttributesInputComponent,
    StandardVariantsInputComponent,
    StandardDatepickerComponent,
    StandardDatetimepickerComponent,
    StandardRteComponent,
    StandardMarkdownComponent,
    StandardDropzoneComponent,
    StandardLanguageBasedInputComponent,
    StandardPriceInputComponent,
    StandardDiscountInputComponent,
    StandardSimpleDiscountInputComponent,
    ImageGalleryComponent,
    ImageZoomComponent,
    JsonPreviewComponent,
    MarkdownComponent,
    ProductStatusComponent,
    ProductSeoPreviewComponent,
    ActionsComponent,
    // directives
    SrcErrDirective,
    // pipes
    AddressPipe,
    EmptyPipe
  ]
})
export class SharedModule {
}
