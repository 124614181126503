<div class="row align-items-center py-4">
  <div class="col-7 col-lg-6 d-flex align-items-center">
    <h6 class="h2 text-white d-inline-block mb-0">{{title}}</h6>
    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
      <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
        <li class="breadcrumb-item"><a routerLink="/platform/dashboard"><i class="fas fa-home"></i></a></li>
        <li *ngFor="let breadcrumb of breadcrumbs;let last=last"
            class="breadcrumb-item" [class.active]="last">
          <a [routerLink]="breadcrumb.path">{{breadcrumb.title}}</a>
        </li>
      </ol>
    </nav>
  </div>
  <div class="col-5 col-lg-6 text-right">
    <ng-content></ng-content>
  </div>
</div>
