import { Component, ElementRef, Inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { ElementBase } from '../../../core/forms/element-base';
import { TagType } from '../../../core/api/core/tags/tag';
import { TagsFilter, TagsService } from '../../../core/api/core/tags/tags.service';

@Component({
  selector: 'app-standard-tags-input',
  templateUrl: './standard-tags-input.component.html',
  styleUrls: ['./standard-tags-input.component.scss']
})
export class StandardTagsInputComponent extends ElementBase<string[]> implements OnInit {

  static nextId = 0;

  private innerTagValue: string | null = null;

  @ViewChild(NgModel, {static: true}) model: NgModel | null = null;
  @ViewChild(NgModel, {static: true, read: ElementRef}) input: ElementRef | null = null;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead | null = null;

  @Input() id = `standard-tags-${StandardTagsInputComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() disabled = false;
  @Input() autofocus: boolean | null = null;
  @Input() size: 'sm' | 'lg' | null = null;
  @Input() autocomplete: TagType | null = null;

  selected: string | null = null;

  focus$: Subject<string> = new Subject<string>();
  click$: Subject<string> = new Subject<string>();

  searching = false;
  searchFailed = false;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance !== null && !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => this.searching = true),
      switchMap((term: string) => {
          if (!this.autocomplete) {
            return of([]);
          }
          const tagsFilter: TagsFilter = new TagsFilter(0, 10);
          tagsFilter.type = this.autocomplete;
          tagsFilter.value = term;
          return this.tagsService.find(tagsFilter).pipe(
            tap(() => this.searchFailed = false),
            map(data => (data.values || []).map(tag => tag.value)),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }));
        }
      ),
      tap(() => this.searching = false)
    );
  };

  set tag(tag: string | null) {
    this.clearSelected();
    const tags = (tag || '').split(/\r\n|\n|\r| |,/);
    if (tags && tags.length > 1) {
      this.value = (this.value || []).concat(tags.map(t => (t || '').trim()).filter(t => t.length > 1));
      this.value = [...new Set(this.value)]; // remove duplicates
      this.innerTagValue = null;
      this.model?.reset();
      return;
    }
    this.innerTagValue = tag;
  }

  get tag(): string | null {
    return this.innerTagValue;
  }

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService, private tagsService: TagsService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.input?.nativeElement.focus());
    }
  }

  remove(tag: string): void {
    this.value = this.value?.filter(t => t !== tag) || [];
  }

  onBackspace(): void {
    if (this.tag) {
      return;
    }
    if (this.selected) {
      this.remove(this.selected);
      this.clearSelected();
      return;
    }
    this.selected = this.value && this.value.length > 0 ? this.value[this.value.length - 1] : null;
  }

  onArrowLeft(): void {
    if (!this.selected || !this.value) {
      return;
    }
    let index = this.value.findIndex(tag => tag === this.selected) || 0;
    index = index - 1;
    if (index < 0) {
      this.clearSelected();
      return;
    }
    this.selected = this.value[index];
  }

  onArrowRight(): void {
    if (!this.selected || !this.value) {
      return;
    }
    let index = this.value.findIndex(tag => tag === this.selected);
    index = index + 1;
    if (index >= this.value.length) {
      this.clearSelected();
      return;
    }
    this.selected = this.value[index];
  }

  onEnter(event: Event): void {
    event.preventDefault();
    this.tryCreateTag();
  }

  clearSelected(): void {
    this.selected = null;
  }

  tryCreateTagAsync(): void {
    setTimeout(() => this.tryCreateTag(), 99);
  }

  tryCreateTag(): void {
    if (!this.tag) {
      return;
    }
    this.tag = this.tag.trim();
    setTimeout(() => {
      if (this.model?.invalid) {
        return;
      }
      this.tag += '\n';
    });
  }
}
