<label [for]="id" *ngIf="label" [class.required-mark]="required$()|async"
       class="mb-0 form-control-label">{{label}}</label>
<div class="form-control h-auto">
  <div class="row">
    <div class="col-6">
      <textarea class="w-100 border-0"
                autocomplete="off"
                [id]="id"
                [placeholder]="placeholder||''"
                [disabled]="disabled"
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                [(ngModel)]="value"
                [name]="name"
                [class.is-invalid]="invalid$|async"></textarea>
    </div>
    <div class="col-6">
      <div class="w-100 h-100 px-3 border-left">
        <app-markdown [value]="value"></app-markdown>
      </div>
    </div>
  </div>
</div>
<small *ngIf="helper" class="form-text text-muted">{{helper}}</small>
<div class="invalid-feedback">
  <span *ngFor="let err of failures$|async;let last=last;">{{err}}<span *ngIf="!last">, </span></span>
</div>
