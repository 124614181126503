import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import * as Keycloak from 'keycloak-js';
import { KeycloakInstance } from 'keycloak-js';
import { fromPromise } from 'rxjs/internal-compatibility';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {

  static auth: any = {};

  static init(): Promise<any> {
    const keycloakAuth: KeycloakInstance = Keycloak(environment.keycloak);
    KeycloakService.auth.loggedIn = false;
    return keycloakAuth.init({onLoad: 'login-required'}).then(() => {
      KeycloakService.auth.loggedIn = true;
      KeycloakService.auth.authz = keycloakAuth;
    });
  }

  logout(): void {
    console.log('*** LOGOUT');
    KeycloakService.auth.loggedIn = false;
    KeycloakService.auth.authz.logout();
  }

  getToken(): Observable<string> {
    if (!KeycloakService.auth.authz || !KeycloakService.auth.authz.token) {
      return observableThrowError('Token missing');
    }
    return fromPromise(new Promise<string>((resolve, reject) => {
      KeycloakService.auth.authz.updateToken(5).then(() => {
        resolve(KeycloakService.auth.authz.token);
      }).catch(() => {
        reject('Failed to refresh token');
      });
    }));
  }

  hasRole(role: string, client: string = 'fontstore-core-srv'): boolean {
    if (!KeycloakService.auth.authz) {
      return false;
    }
    return KeycloakService.auth.authz.hasResourceRole(role, client) ||
      KeycloakService.auth.authz.hasResourceRole('ROLE_FS_ROOT', client);
  }

  hasAnyRole(roles: string[], client: string = 'fontstore-core-srv'): boolean {
    if (!KeycloakService.auth.authz) {
      return false;
    }
    let hasRole = false;
    roles.forEach(role => {
      hasRole = hasRole || KeycloakService.auth.authz.hasResourceRole(role, client);
    });
    return hasRole || KeycloakService.auth.authz.hasResourceRole('ROLE_FS_ROOT', client);
  }
}
