import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type Theme = 'white' | 'red' | 'yellow' | 'green' | 'blue' | 'purple';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private static THEME_KEY = 'FS_THEME';

  private theme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(localStorage.getItem(SettingsService.THEME_KEY) as Theme || 'white');

  theme$: Observable<Theme> = this.theme.asObservable();
  background$: Observable<string> = this.theme.pipe(map(theme => `bg-${theme}`));
  backgroundWithGradient$: Observable<string> = this.theme.pipe(map(theme => `bg-gradient-${theme}`));
  navbarDark$: Observable<boolean> = this.theme.pipe(map(theme => theme !== 'white'));
  spinner$: Observable<string> = this.theme.pipe(map(() => '#8898aa'));

  constructor() {
  }

  setTheme(theme: Theme): void {
    this.theme.next(theme);
    localStorage.setItem(SettingsService.THEME_KEY, theme);
  }

  getTheme(): Theme {
    return this.theme.getValue();
  }

  getAvailableThemes(): Theme[] {
    return ['white', 'red', 'yellow', 'green', 'blue', 'purple'];
  }
}
