import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private toastr: ToastrService) {
  }

  success(message: string, title: string = ''): void {
    this.show(title, message, 'success');
  }

  error(message: string, title: string = ''): void {
    this.show(title, message, 'danger');
  }

  warning(message: string, title: string = ''): void {
    this.show(title, message, 'warning');
  }

  info(message: string, title: string = ''): void {
    this.show(title, message, 'info');
  }

  httpError(title: string, res: HttpErrorResponse): void {
    this.show(title, res && res.error && res.error.message || 'Unknown error', 'danger');
  }

  show(title: string, message: string, level: 'danger' | 'info' | 'warning' | 'success' | 'default' = 'default'): void {
    this.toastr.show(`
<span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
<div class="alert-text"</div>
<span class="alert-title" data-notify="title">${title}</span>
<span data-notify="message">${message}</span></div>`, '', {
      timeOut: 8000,
      closeButton: true,
      enableHtml: true,
      tapToDismiss: false,
      titleClass: 'alert-title',
      positionClass: 'toast-top-center',
      toastClass: `ngx-toastr alert alert-dismissible alert-${level} alert-notify`,
    });
  }
}
