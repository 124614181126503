import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';

export interface Language {
  name?: string;
  flag?: string;
  code?: string;
}

@Injectable({
  providedIn: 'root'
})
export class I18nService implements OnDestroy {

  private static LANG_KEY = 'FS_LANG';

  private language: BehaviorSubject<Language | null> = new BehaviorSubject<Language | null>(null);
  private languages: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([{
    name: 'ENGLISH',
    flag: 'gb',
    code: 'en',
  }, {
    name: 'SLOVENIAN',
    flag: 'si',
    code: 'sl',
  }]);

  language$: Observable<Language | null> = this.language.asObservable();
  languages$: Observable<Language[]> = this.languages.asObservable();

  constructor(private translate: TranslateService) {
  }

  ngOnDestroy(): void {
    this.language.complete();
    this.languages.complete();
  }

  initialize(): void {
    const languages: Language[] = this.languages.getValue();

    this.translate.addLangs(languages.map((lang: Language) => lang.code) as string[]);
    this.translate.setDefaultLang('en');
    this.translate.use(localStorage.getItem(I18nService.LANG_KEY) || this.translate.getBrowserLang() || 'en');
    moment.locale(this.translate.currentLang);

    this.language.next(languages.find((lang: Language) => lang.code === this.translate.currentLang) || null);
  }

  select(lang: Language): void {
    this.language.next(lang);
    this.translate.use(lang.code as string);
    moment.locale(lang.code);
    localStorage.setItem(I18nService.LANG_KEY, lang.code as string);
  }
}
