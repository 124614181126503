import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class NgbDatepickerFormatterService extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const date = moment(value, 'D.M.YYYY');
    if (!date.isValid()) {
      return null;
    }
    return {
      year: date.get('year'),
      month: date.get('month') + 1,
      day: date.get('date')
    } as NgbDateStruct;
  }

  format(date: NgbDateStruct | null): string {
    return date ? `${date.day}. ${date.month}. ${date.year}` : '';
  }
}
