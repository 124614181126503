import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeycloakProfile } from 'keycloak-js';

import { KeycloakService } from './keycloak.service';

export type AuthUserRole =
  'ROLE_FS_USER' |
  'ROLE_FS_ROOT';

export type AuthContext =
  'realm' |
  'frontstore-core-srv' |
  'frontstore-orders-srv' |
  'frontstore-products-srv';

export class AuthUserContextRole {
  context?: AuthContext;
  role?: AuthUserRole;
}

export class AuthUser {
  authenticated = false;
  profile?: KeycloakProfile;

  get displayName(): string {
    return ((this.profile && this.profile.firstName || '') + ' ' + (this.profile && this.profile.lastName || '')).trim();
  }

  get username(): string {
    return (this.profile && this.profile.username || '').trim();
  }

  get store(): string {
    const attributes = (this.profile as any).attributes;
    return attributes && attributes.store && attributes.store.length > 0 && attributes.store[0];
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  private hasExtraAuthorities = false;
  private authoritiesBackup: AuthUserContextRole[] | null = null;

  private user: BehaviorSubject<AuthUser | null> = new BehaviorSubject<AuthUser | null>(null);
  private authorities: BehaviorSubject<AuthUserContextRole[] | null> = new BehaviorSubject<AuthUserContextRole[] | null>(null);

  user$: Observable<AuthUser | null> = this.user.asObservable();
  authorities$: Observable<AuthUserContextRole[] | null> = this.authorities.asObservable();
  groupedAuthorities$: Observable<{ context: AuthContext, roles: AuthUserRole[] }[]> = this.authorities$.pipe(
    map(authorities => {
      const tmp: { [context: string]: AuthUserRole[] } = {};
      (authorities || []).forEach((authority: AuthUserContextRole) => {
        if (!authority || !authority.context || !authority.role) {
          return;
        }
        tmp[authority.context] = tmp[authority.context] || [];
        tmp[authority.context].push(authority.role);
      });
      return Object.keys(tmp).map(key => ({context: key as AuthContext, roles: tmp[key]}));
    })
  );

  constructor(private keycloakService: KeycloakService) {
    if (!KeycloakService.auth.authz) {
      return;
    }

    const authorities: AuthUserContextRole[] = [];
    KeycloakService.auth.authz.realmAccess.roles.forEach((role: string) => {
      if (['offline_access', 'uma_authorization']
        .indexOf(role) >= 0) { // blacklisted roles
        return;
      }
      authorities.push({context: 'realm' as AuthContext, role: role as AuthUserRole});
    });
    Object.keys(KeycloakService.auth.authz.resourceAccess).forEach(resource => {
      if (['frontstore-stores-srv', 'frontstore-core-srv'].indexOf(resource as AuthContext) === -1) { // whitelisted resources
        return;
      }
      KeycloakService.auth.authz.resourceAccess[resource].roles.forEach((role: string) => {
        authorities.push({context: resource as AuthContext, role: role as AuthUserRole});
      });
    });
    this.authorities.next(authorities);

    KeycloakService.auth.authz.loadUserProfile().then((profile: KeycloakProfile) => {
      const user = new AuthUser();
      user.authenticated = true;
      user.profile = profile;
      this.user.next(user);
    }, console.error);
  }

  ngOnDestroy(): void {
    this.user.complete();
    this.authorities.complete();
  }

  getUser(): AuthUser | null {
    return this.user.getValue();
  }

  getUsername(): string | null {
    const user = this.getUser();
    return user && user.username || null;
  }

  hasRole(role: AuthUserRole, context: AuthContext = 'frontstore-core-srv'): boolean {
    if (this.hasExtraAuthorities) {
      return (this.authorities.getValue() || [])
        .findIndex(cr => cr.role === role && cr.context === context) >= 0;
    }
    return this.keycloakService.hasRole(role, context);
  }

  hasAnyRole(roles: AuthUserRole[], context: AuthContext = 'frontstore-core-srv'): boolean {
    if (this.hasExtraAuthorities) {
      return (this.authorities.getValue() || [])
        .filter(cr => roles.findIndex(r => cr.role === r && cr.context === context) >= 0).length > 0;
    }
    return this.keycloakService.hasAnyRole(roles, context);
  }

  hasRoleAsync(role: AuthUserRole): Observable<boolean> {
    return this.authorities$.pipe(map(() => this.hasRole(role)));
  }

  hasAnyRoleAsync(roles: AuthUserRole[], context: AuthContext = 'frontstore-core-srv'): Observable<boolean> {
    return this.authorities$.pipe(map(() => this.hasAnyRole(roles, context)));
  }

  logout(): void {
    this.keycloakService.logout();
  }
}
