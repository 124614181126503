import { Component, Inject, Input, OnInit, Optional, Self } from '@angular/core';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgControl, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ElementBase } from '../../../core/forms/element-base';
import { StoreService } from '../../../core/auth/store.service';
import { Language } from '../../../core/util/i18n.service';

@Component({
  selector: 'app-standard-language-based-input',
  templateUrl: './standard-language-based-input.component.html',
  styleUrls: ['./standard-language-based-input.component.scss']
})
export class StandardLanguageBasedInputComponent extends ElementBase<any> implements OnInit {

  static nextId = 0;

  model: NgModel | null = null;

  @Input() id = `standard-language-based-input-${StandardLanguageBasedInputComponent.nextId++}`;
  @Input() label: string | null = null;
  @Input() helper: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() disabled = false;
  @Input() readonly: boolean | null = null;
  @Input() type: 'simple' | 'text' | 'markdown' | 'html' = 'simple';

  languages$: Observable<Language[]> = this.store.languages$;

  language: string | null = null;
  val: any | null = null;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[],
              @Optional() @Self() ngControl: NgControl, translate: TranslateService, private store: StoreService) {
    super(validators, asyncValidators, ngControl, translate);
  }

  ngOnInit(): void {
    this.setLanguage(this.store.getDefaultLanguage());
  }

  writeValue(value: any): void {
    super.writeValue(this.store.getTranslations(value));
    if (this.language) {
      this.val = this.value[this.language];
    }
  }

  setLanguage(language: Language): void {
    if (!language || !language.code || !this.value) {
      return;
    }
    this.language = language.code;
    this.val = this.value[this.language];
  }

  update(): void {
    if (!this.language) {
      return;
    }
    const tmp = this.value ? {...this.value} : {};
    tmp[this.language] = this.val;
    this.value = tmp;
  }
}
